import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { BuyerEntity } from 'modules/Buyer/types/buyer.type';

import { buyerLogin, me } from './buyer.action';
import { IBuyerState } from './types/buyerState.interface';

const initialState: IBuyerState = {
  loading: false
};

const buyerSlice = createSlice({
  name: 'buyer',
  initialState,
  reducers: {
    setErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(buyerLogin.fulfilled, (state) => {
        state.errorMessage = undefined;
        state.loading = false;
      })
      .addCase(buyerLogin.pending, (state) => {
        state.errorMessage = undefined;
        state.loading = true;
      })
      .addCase(buyerLogin.rejected, (state) => {
        state.errorMessage = 'Datos ingresados incorrectamente';
        state.loading = false;
      })

      .addCase(me.fulfilled, (state, action: PayloadAction<BuyerEntity>) => {
        state.errorMessage = undefined;
        state.loading = false;
        state.me = action.payload;
      })
      .addCase(me.rejected, (state) => {
        state.errorMessage = 'error getting buyer info';
        state.me = undefined;
      })
      .addCase(me.pending, (state) => {
        state.loading = true;
      });
  }
});

export const { setErrorMessage } = buyerSlice.actions;

export default buyerSlice.reducer;
