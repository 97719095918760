import { useAppSelector } from 'hooks/useAppSelector';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Button, Container, useTheme } from '@mui/material';

import TitleLabel from 'design-system/Layouts/TitleLabels';

import { userSelector } from 'redux/user/user.selector';

import Roles from 'types/roles.enum';

import CommerceRoutes from '../types/routes.enum';
import { TransactionsTableMobile } from './TransactionsTableMobile';

export const TransactionsMobile = () => {
  const navigate = useNavigate();
  const { role } = useAppSelector(userSelector);
  const isCommerce = [Roles.ADMIN_COMMERCE, Roles.COMMERCE].includes(role);
  const theme = useTheme();
  const [isContentVisible, setIsContentVisible] = useState(false);
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          backgroundColor: theme.palette.background.paper
        }}
        mt={'1.875rem'}>
        <Box
          sx={{
            my: '1rem',
            border: `1px solid ${theme.palette.primary.light}`,
            borderRadius: '0.5rem'
          }}>
          <Container fixed sx={{ minWidth: '100%' }}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{ margin: '0.5rem' }}>
              <TitleLabel fontSize="1rem">
                Historial de transacciones
              </TitleLabel>
              <Button onClick={() => setIsContentVisible(!isContentVisible)}>
                {isContentVisible ? (
                  <KeyboardArrowUpIcon
                    style={{ color: theme.palette.primary.main }}
                  />
                ) : (
                  <KeyboardArrowDownIcon
                    style={{ color: theme.palette.primary.main }}
                  />
                )}
              </Button>
            </Box>

            {isContentVisible && <TransactionsTableMobile />}
          </Container>
        </Box>
      </Box>
      {isCommerce && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            maxWidth: '100%'
          }}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            onClick={() => {
              navigate(`/commerce/${CommerceRoutes.TRANSACTIONS_LOT}`);
            }}
            sx={{ mt: 1, mb: 1, width: '75%' }}>
            Realizar cierre de lote
          </Button>
        </Box>
      )}
    </>
  );
};
