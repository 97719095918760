import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  createCashierAction,
  findAllCashiersAction,
  findAllDeletedCashiersAction,
  findOneCashierAction,
  forgotPasswordAction,
  me,
  removeCashierAction,
  restorePasswordAction,
  updateCashierAction
} from './casher.action';
import { Cashier } from './types/casher.type';
import {
  CashierModalStatus,
  ICashiersState
} from './types/casherState.interface';

const initialState: ICashiersState = {
  loading: false,
  cashiers: [],
  cashierModal: {
    isOpen: false,
    selectedCashier: undefined
  }
};

const cashiersSlice = createSlice({
  name: 'cashiers',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setCashierModalIsOpen: (state, action: PayloadAction<boolean>) => {
      state.cashierModal.isOpen = action.payload;
    },
    setSelectedCashier: (state, action: PayloadAction<Cashier | undefined>) => {
      state.cashierModal.selectedCashier = action.payload;
    },
    setCashierModalStatus: (
      state,
      action: PayloadAction<CashierModalStatus>
    ) => {
      state.cashierModal.status = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        createCashierAction.fulfilled,
        (state, action: PayloadAction<Cashier>) => {
          state.cashiers.push(action.payload);
          state.loading = false;
        }
      )
      .addCase(createCashierAction.pending, (state) => {
        state.loading = true;
        state.errorMessage = undefined;
      })
      .addCase(createCashierAction.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage =
          (action.payload as string) === 'Cashier is already registered'
            ? 'Email ya registrado'
            : 'Error creando cajero';
      })

      .addCase(
        findAllCashiersAction.fulfilled,
        (state, action: PayloadAction<Cashier[]>) => {
          state.cashiers = action.payload;
          state.loading = false;
        }
      )
      .addCase(findAllCashiersAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(findAllCashiersAction.rejected, (state) => {
        state.loading = false;
      })
      .addCase(
        findAllDeletedCashiersAction.fulfilled,
        (state, action: PayloadAction<Cashier[]>) => {
          state.cashiers = action.payload;
          state.loading = false;
        }
      )
      .addCase(findAllDeletedCashiersAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(findAllDeletedCashiersAction.rejected, (state) => {
        state.loading = false;
      })
      .addCase(
        findOneCashierAction.fulfilled,
        (state, action: PayloadAction<Cashier>) => {
          const index = state.cashiers.findIndex(
            (c) => c.id === action.payload.id
          );
          if (index !== -1) {
            state.cashiers[index] = action.payload;
          } else {
            state.cashiers.push(action.payload);
          }
          state.loading = false;

          if (state.cashierModal.isOpen) {
            state.cashierModal.selectedCashier = action.payload;
          }
        }
      )
      .addCase(findOneCashierAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(findOneCashierAction.rejected, (state) => {
        state.loading = false;
      })

      .addCase(
        updateCashierAction.fulfilled,
        (state, action: PayloadAction<Cashier>) => {
          const index = state.cashiers.findIndex(
            (c) => c.id === action.payload.id
          );
          if (index !== -1) {
            state.cashiers[index] = action.payload;
          }
          state.loading = false;
        }
      )
      .addCase(updateCashierAction.pending, (state) => {
        state.loading = true;
        state.errorMessage = undefined;
      })
      .addCase(updateCashierAction.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage =
          (action.payload as string) === 'Cashier is already registered'
            ? 'Email ya registrado'
            : 'Error creando cajero';
      })
      .addCase(
        removeCashierAction.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.cashiers = state.cashiers.filter(
            (c) => c.id !== action.payload
          );
          state.loading = false;
        }
      )
      .addCase(removeCashierAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeCashierAction.rejected, (state) => {
        state.loading = false;
      })

      .addCase(me.fulfilled, (state, action: PayloadAction<Cashier>) => {
        state.me = action.payload;
        state.loading = false;
      })
      .addCase(me.pending, (state) => {
        state.loading = true;
      })
      .addCase(me.rejected, (state) => {
        state.loading = false;
      })
      .addCase(
        forgotPasswordAction.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.loading = false;
        }
      )
      .addCase(forgotPasswordAction.pending, (state) => {
        state.forgotPasswordErrorMessage = undefined;
        state.loading = true;
      })
      .addCase(forgotPasswordAction.rejected, (state, action) => {
        state.forgotPasswordErrorMessage = action.payload as string;
        state.loading = false;
      })
      .addCase(
        restorePasswordAction.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.loading = false;
        }
      )
      .addCase(restorePasswordAction.pending, (state) => {
        state.loading = true;
        state.errorMessage = undefined;
      })
      .addCase(restorePasswordAction.rejected, (state) => {
        state.loading = false;
        state.errorMessage = 'Error cambiando la contraseña';
      });
  }
});

export default cashiersSlice.reducer;
export const {
  setLoading,
  setCashierModalIsOpen,
  setSelectedCashier,
  setCashierModalStatus
} = cashiersSlice.actions;
