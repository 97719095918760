import LogoImg from 'assets/imgs/logo.png';
import { Form, Formik } from 'formik';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PasswordRequestType } from 'services/commerce.service';
import * as Yup from 'yup';

import { Box, Button, TextField, Typography, useTheme } from '@mui/material';

import ModalApprove from 'components/ModalApproved';

import Label from 'design-system/Label';

import { restorePasswordAction } from 'redux/casher/casher.action';
import { CashierSelector } from 'redux/casher/casher.selector';
import { commerceSelector } from 'redux/commerce/commerce.selector';

import CommerceRoutes from '../types/routes.enum';

const RestorePassword: React.FC = () => {
  const [isSent, setIsSent] = useState<boolean>(false);

  const isLargeScreen = useIsLargeScreen();
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { commerce: userData } = useAppSelector(commerceSelector);
  const { errorMessage } = useAppSelector(CashierSelector);

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required('Contraseña requerida')
      .min(8, 'La contraseña debe tener al menos 8 caracteres'),
    confirmPassword: Yup.string()
      .required('Confirmar contraseña es requerido')
      .oneOf([Yup.ref('password')], 'Las contraseñas deben coincidir')
  });

  const handleSubmit = async (values: PasswordRequestType) => {
    const RestorePayload = {
      email: userData?.user as string,
      password: values.password,
      name: userData?.name as string
    };

    const response = await dispatch(restorePasswordAction(RestorePayload));
    if (response.meta.requestStatus === 'fulfilled') {
      setIsSent(true);
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: isLargeScreen
          ? theme.palette.primary.main
          : theme.palette.background.default
      }}>
      <Box
        sx={{
          width: isLargeScreen ? 384 + 59 * 2 : '90%',
          maxWidth: '90%',
          margin: 'auto',
          border: isLargeScreen
            ? `1px solid ${theme.palette.primary.main}`
            : undefined,
          padding: '3.6875rem',
          borderRadius: '1.5rem',
          background: theme.palette.background.default
        }}>
        {isSent ? (
          <ModalApprove
            ApproveLabel="Se ha actualizado su contraseña correctamente"
            handleClose={() => {
              navigate(`/commerce/${CommerceRoutes.HOME}`);
            }}
            buttonText="Volver"
          />
        ) : (
          <>
            {isLargeScreen && (
              <Box
                sx={{
                  maxWidth: '8.25rem',
                  margin: 'auto',
                  marginBottom: '4.25rem'
                }}>
                <img src={LogoImg} alt="logo" width={'100%'} />
              </Box>
            )}

            <Box
              sx={{
                marginBottom: '1.5rem',
                '& > *': { marginBottom: '0.5rem' }
              }}>
              <Label>Mail</Label>
              <Label color={'gray'}>{`${userData?.user}`}</Label>
              <Label>Usuario</Label>
              <Label color={'gray'}>{`${userData?.name}`}</Label>
              <Label>Número de afiliado</Label>
              <Label color={'gray'}>{`${userData?.commercialCode}`}</Label>
            </Box>
            <Formik
              initialValues={{
                email: '',
                password: '',
                confirmPassword: '',
                cashier: false
              }}
              onSubmit={(values) => handleSubmit(values)}
              validationSchema={validationSchema}>
              {(formik) => (
                <Form>
                  <Box mb="1rem" mt="0.75rem">
                    <Label
                      color={!!formik.errors.password ? 'error' : undefined}>
                      Nueva contraseña
                    </Label>
                    <TextField
                      required
                      fullWidth
                      size={isLargeScreen ? undefined : 'small'}
                      placeholder="Ingresar contraseña"
                      name="password"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      value={formik.values.password}
                      onChange={(e) =>
                        formik.setFieldValue('password', e.target.value)
                      }
                      error={!!formik.errors.password}
                      helperText={formik.errors.password as string}
                    />
                  </Box>
                  <Box mb="1rem" mt="0.75rem">
                    <Label
                      color={
                        !!formik.errors.confirmPassword ? 'error' : undefined
                      }>
                      Repetir nueva contraseña
                    </Label>
                    <TextField
                      required
                      fullWidth
                      size={isLargeScreen ? undefined : 'small'}
                      placeholder="Ingresar contraseña"
                      name="confirmPassword"
                      type="password"
                      id="confirmPassword"
                      autoComplete="current-password"
                      value={formik.values.confirmPassword}
                      onChange={(e) =>
                        formik.setFieldValue('confirmPassword', e.target.value)
                      }
                      error={!!formik.errors.confirmPassword}
                      helperText={
                        formik.errors.confirmPassword
                          ? 'Las contraseñas no coinciden'
                          : ''
                      }
                    />
                  </Box>
                  {errorMessage && (
                    <Box bgcolor={theme.palette.background.paper} m="auto">
                      <Typography variant="body1" color="error">
                        {errorMessage}
                      </Typography>
                    </Box>
                  )}
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 2, mb: 1 }}>
                    Cambiar contraseña
                  </Button>
                  <Button
                    type="button"
                    fullWidth
                    variant="outlined"
                    sx={{ mt: 2, mb: 1 }}
                    onClick={() => {
                      navigate(`/commerce/${CommerceRoutes.HOME}`);
                    }}>
                    Cancelar
                  </Button>
                </Form>
              )}
            </Formik>
          </>
        )}
      </Box>
    </Box>
  );
};

export default RestorePassword;
