import LogoImg from 'assets/imgs/logo.png';
import { Form, Formik } from 'formik';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { forgotPassword } from 'services/commerce.service';
import * as Yup from 'yup';

import { Box, Button, TextField, useTheme } from '@mui/material';

import ModalApprove from 'components/ModalApproved';

import Label from 'design-system/Label';

import { forgotPasswordAction } from 'redux/casher/casher.action';
import { CashierSelector } from 'redux/casher/casher.selector';

import CommerceRoutes from '../types/routes.enum';

const ForgotPassword = () => {
  const [isSent, setIsSent] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const { forgotPasswordErrorMessage } = useAppSelector(CashierSelector);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Email no valido').required('Email es requerido'),
    user: Yup.string().required('Usuario requerido'),
    affiliate: Yup.string().required('Nro de afiliado requerido')
  });

  const handleSubmit = async (values: forgotPassword) => {
    const RestorePayload = {
      email: values.email,
      user: values.user,
      affiliate: values.affiliate
    };

    const response = await dispatch(forgotPasswordAction(RestorePayload));
    if (response.meta.requestStatus === 'fulfilled') {
      setIsSent(true);
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: `${theme.palette.primary.main}`
      }}>
      <Box
        sx={{
          width: 384 + 59 * 2,
          maxWidth: '90%',
          margin: 'auto',
          border: `1px solid ${theme.palette.primary.main}`,
          padding: '3.6875rem',
          borderRadius: '1.5rem',
          background: theme.palette.background.default
        }}>
        {isSent ? (
          <ModalApprove
            ApproveLabel="Estimado usuario, le llegará en breves un correo a su casilla con su nueva contraseña"
            handleClose={() => {
              navigate(`/commerce/${CommerceRoutes.LOGIN}`);
            }}
            buttonText="Volver"
          />
        ) : (
          <>
            <Box
              sx={{
                maxWidth: '8.25rem',
                margin: 'auto',
                marginBottom: '4.25rem'
              }}>
              <img src={LogoImg} alt="logo" width={'100%'} />
            </Box>
            <Formik
              initialValues={{
                email: '',
                user: '',
                affiliate: '',
                cashier: false
              }}
              onSubmit={(values) => handleSubmit(values)}
              validationSchema={validationSchema}>
              {(formik) => (
                <Form>
                  <Box mb="1rem" mt="0.75rem">
                    <Label
                      color={!!formik.errors.affiliate ? 'error' : undefined}>
                      Afiliado
                    </Label>
                    <TextField
                      required
                      fullWidth
                      id="affiliate"
                      name="affiliate"
                      placeholder="Ingresar codigo de afiliado"
                      autoComplete="affiliate"
                      value={formik.values.affiliate}
                      onChange={(e) =>
                        formik.setFieldValue('affiliate', e.target.value)
                      }
                      autoFocus
                      error={!!formik.errors.affiliate}
                    />
                  </Box>
                  <Box mb="1rem" mt="0.75rem">
                    <Label color={!!formik.errors.user ? 'error' : undefined}>
                      Usuario
                    </Label>
                    <TextField
                      required
                      fullWidth
                      id="user"
                      name="user"
                      placeholder="Ingresar usuario"
                      autoComplete="user"
                      value={formik.values.user}
                      onChange={(e) =>
                        formik.setFieldValue('user', e.target.value)
                      }
                      error={!!formik.errors.user}
                    />
                  </Box>

                  <Box mb="1rem" mt="0.75rem">
                    <Label color={!!formik.errors.email ? 'error' : undefined}>
                      Email
                    </Label>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      name="email"
                      placeholder="Ingresar usuario"
                      autoComplete="email"
                      value={formik.values.email}
                      onChange={(e) =>
                        formik.setFieldValue('email', e.target.value)
                      }
                      error={!!formik.errors.email}
                    />
                  </Box>
                  {forgotPasswordErrorMessage && (
                    <Label color="error">{forgotPasswordErrorMessage}</Label>
                  )}

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 2, mb: 1 }}>
                    Resetear contraseña
                  </Button>
                  <Button
                    type="button"
                    fullWidth
                    variant="outlined"
                    sx={{ mt: 2, mb: 1 }}
                    onClick={() => {
                      navigate(`/commerce/${CommerceRoutes.LOGIN}`);
                    }}>
                    Cancelar
                  </Button>
                </Form>
              )}
            </Formik>
          </>
        )}
      </Box>
    </Box>
  );
};
export default ForgotPassword;
