import { PublicBuyer } from 'modules/Buyer/types/buyer.type';

import {
  PublicCashier,
  PublicCommerce
} from 'modules/Commerce/types/commerce.type';

export type Transaction = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: null;
  transactionNumber: string | null;
  authorizationNumber: null;
  dues: number;
  status: TransactionStatus;
  currency: Currency;
  amount: number;
  closedLot: Date | null;
  buyer: PublicBuyer;
  commerce: PublicCommerce;
  cashier: PublicCashier | null;
};

export enum TransactionStatus {
  APPROVED = 'approved',
  VERIFIED = 'verified',
  'REJECTED-BY-CD' = 'rejected-by-cd',
  'REJECTED-BY-BUYER' = 'rejected-by-buyer',
  'REJECTED-BY-COMMERCE' = 'rejected-by-commerce',
  PENDING = 'pending',
  CANCELED = 'canceled',
  ANNULLED = 'annulled'
}

export enum Currency {
  USD = 'USD',
  UYU = 'UYU'
}
export default Currency;
