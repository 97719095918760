import { useAppSelector } from 'hooks/useAppSelector';
import useIsLargeScreen from 'hooks/useIsLargeScreen';

import { Grid, Skeleton } from '@mui/material';

import DescriptionLabel from 'design-system/Layouts/DescriptionLabel';
import TitleLabel from 'design-system/Layouts/TitleLabels';

import { commerceSelector } from 'redux/commerce/commerce.selector';

export const CommerceData = () => {
  const { commerce: userData } = useAppSelector(commerceSelector);
  const isLargeScreen = useIsLargeScreen();
  return (
    <Grid
      container
      sx={{
        my: isLargeScreen ? '1rem 1rem' : '0.5rem 0'
      }}>
      {isLargeScreen && (
        <Grid item xs={12}>
          <TitleLabel>Datos del comercio</TitleLabel>
        </Grid>
      )}
      <Grid
        item
        xs={isLargeScreen ? 4 : 12}
        sx={{
          margin: isLargeScreen ? '1rem' : '0',
          marginTop: isLargeScreen ? undefined : '0'
        }}>
        {userData?.RUT ? (
          <DescriptionLabel>{`RUT: ${userData?.RUT}`}</DescriptionLabel>
        ) : (
          <Skeleton />
        )}
      </Grid>
      <Grid
        item
        xs={isLargeScreen ? 6 : 12}
        sx={{
          margin: isLargeScreen ? '1rem' : '0',
          marginTop: isLargeScreen ? undefined : '0.5rem'
        }}>
        {userData?.businessName ? (
          <DescriptionLabel>{`Razón Social: ${userData?.businessName}`}</DescriptionLabel>
        ) : (
          <Skeleton />
        )}
      </Grid>
      <Grid
        item
        xs={isLargeScreen ? 4 : 12}
        sx={{
          margin: isLargeScreen ? '1rem' : '0',
          marginTop: isLargeScreen ? undefined : '0.5rem'
        }}>
        {userData?.commercialCode ? (
          <DescriptionLabel>{`Código de Comercio: ${userData?.commercialCode}`}</DescriptionLabel>
        ) : (
          <Skeleton />
        )}
      </Grid>
      <Grid
        item
        xs={isLargeScreen ? 6 : 12}
        sx={{
          margin: isLargeScreen ? '1rem' : '0',
          marginTop: isLargeScreen ? undefined : '0.5rem'
        }}>
        {userData?.fiscalAddress ? (
          <DescriptionLabel>{`Dirección: ${userData?.fiscalAddress}`}</DescriptionLabel>
        ) : (
          <Skeleton />
        )}
      </Grid>
    </Grid>
  );
};
