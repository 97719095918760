import { Typography, TypographyProps } from '@mui/material';

interface ILabelProps {
  children: string;
  color?: TypographyProps['color'];
  fontSize?: string;
  fontWeight?: string;
}

const TitleLabel = ({
  children,
  color,
  fontSize = '1.25rem',
  fontWeight = '700'
}: ILabelProps) => {
  return (
    <Typography
      fontWeight={fontWeight}
      fontSize={fontSize}
      fontStyle={'normal'}
      fontFamily={'Poppins'}
      color={color ? color : 'primary'}>
      {children}
    </Typography>
  );
};
export default TitleLabel;
