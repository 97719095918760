import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import React, { useEffect, useState } from 'react';

import { Box, Switch, Typography, useTheme } from '@mui/material';

import TitleLabel from 'design-system/Layouts/TitleLabels';
import { Colors } from 'design-system/theme';

import { me } from 'redux/buyer/buyer.action';
import { me as meCashier } from 'redux/casher/casher.action';
import { CashierSelector } from 'redux/casher/casher.selector';
import { commerceSelector } from 'redux/commerce/commerce.selector';
import { getForCloseTransactions } from 'redux/transaction/transaction.action';
import { transactionSelector } from 'redux/transaction/transaction.selector';
import Currency, {
  Transaction
} from 'redux/transaction/types/transaction.type';
import { userSelector } from 'redux/user/user.selector';

import Roles from 'types/roles.enum';

import { CloseLoteSkeleton } from './CloseLoteSkeleton';
import TransactionsCloseLot from './TransactionsCloseLot';

export const CloseLotTableMobile: React.FC = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const [allCashiers, setAllCashiers] = useState(true);

  const { transactionsForClose, loading } = useAppSelector(transactionSelector);
  const { me: cashierData } = useAppSelector(CashierSelector);
  const { commerce } = useAppSelector(commerceSelector);
  const { role } = useAppSelector(userSelector);

  const userName: string =
    role === Roles.ADMIN_COMMERCE
      ? commerce?.name.toString() || '---'
      : cashierData?.name.toString() || '---';

  const USDTransactions: Transaction[] = [];
  let USDTransactionsTotal: number = 0;
  const UYUTransactions: Transaction[] = [];
  let UYUTransactionsTotal: number = 0;

  if (transactionsForClose.length > 0)
    transactionsForClose.forEach((transaction) => {
      if (transaction.currency === Currency.UYU) {
        UYUTransactions.push(transaction);
        UYUTransactionsTotal = UYUTransactionsTotal + transaction.amount;
      } else if (transaction.currency === Currency.USD) {
        USDTransactions.push(transaction);
        USDTransactionsTotal = USDTransactionsTotal + transaction.amount;
      }
    });

  useEffect(() => {
    dispatch(me());
    if (role === Roles.COMMERCE) dispatch(meCashier());
  }, [dispatch, role]);

  useEffect(() => {
    dispatch(
      getForCloseTransactions({
        forAll: role === Roles.COMMERCE ? false : allCashiers
      })
    );
  }, [dispatch, allCashiers, role]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '65vh'
        }}>
        {role === Roles.ADMIN_COMMERCE && (
          <Box display="flex" alignItems="center" mb="1rem">
            <Typography
              alignSelf="center"
              mr={1}
              sx={{
                color: Colors.gray,
                fontFamily: 'Poppins',
                fontSize: '0.75rem'
              }}>
              Cerrar todas las cajas
            </Typography>
            <Switch
              defaultChecked
              size="small"
              value={allCashiers}
              onChange={() => setAllCashiers(!allCashiers)}
            />
          </Box>
        )}
        <Box
          sx={{
            maxHeight: '550px',
            overflowY: 'auto',
            backgroundColor: theme.palette.background.paper,
            '&::-webkit-scrollbar': {
              width: '0.25rem',
              height: '0.5rem'
            },
            '&::-webkit-scrollbar-track': {
              background: theme.palette.background.paper
            },
            '&::-webkit-scrollbar-thumb': {
              background: Colors.fuzzy,
              borderRadius: '10px'
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: '#555'
            },
            scrollbarWidth: 'thin',
            scrollbarColor: `${Colors.primary} ${theme.palette.background.paper}`
          }}>
          <Box sx={{ padding: '0.25rem' }}>
            <TitleLabel fontSize="1rem">{userName}</TitleLabel>
          </Box>
          {!loading ? (
            <>
              <Box
                sx={{
                  padding: '0.5rem',
                  borderTop: `1px solid ${theme.palette.primary.light}`
                }}>
                <TransactionsCloseLot
                  TransactionsTotal={USDTransactionsTotal}
                  Currency={'USD'}
                />
              </Box>
              {
                <Box
                  sx={{
                    padding: '0.5rem',
                    borderTop: `1spx solid ${theme.palette.primary.light}`
                  }}>
                  <TransactionsCloseLot
                    TransactionsTotal={UYUTransactionsTotal}
                    Currency={'Pesos'}
                  />
                </Box>
              }
            </>
          ) : (
            <CloseLoteSkeleton />
          )}
        </Box>
      </Box>
    </>
  );
};
