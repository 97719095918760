import { Typography, TypographyProps } from '@mui/material';

interface ILabelProps {
  children: string;
  color?: TypographyProps['color'];
  fontSize?: number;
  fontWeight?: number;
}

const Label = ({ children, color, fontSize, fontWeight }: ILabelProps) => {
  return (
    <Typography
      fontWeight={fontWeight ? fontWeight : 700}
      fontSize={fontSize ? fontSize : 12}
      color={color ? color : 'primary'}>
      {children}
    </Typography>
  );
};

export default Label;
