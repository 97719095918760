import { FormikProps } from 'formik';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React, { useState } from 'react';

import { Box, Button, Grid, useTheme } from '@mui/material';

import TitleLabel from 'design-system/Layouts/TitleLabels';

import AdminCash from './AdminCash';
import NewCashier from './NewCashier';
import RestoreCashier from './RestoreCashier';

enum TabsEnum {
  NEW_CASHIER = 'NEW_CASHIER',
  ADMIN_CASHIER = 'ADMIN_CASHIER',
  RESTORE = 'RESTORE'
}

export interface CashRegristrationProps {
  formik: FormikProps<{
    cashID: string;
    password: string;
    storeAddress: string;
    location: string;
    department: string;
    email: string;
    phoneNumber: string;
  }>;
}
export const CashRegistrationForm: React.FC<CashRegristrationProps> = ({
  formik
}) => {
  const theme = useTheme();
  const isLargeScreen = useIsLargeScreen();
  const [activeButton, setActiveButton] = useState<TabsEnum>(
    TabsEnum.NEW_CASHIER
  );
  return (
    <>
      {isLargeScreen && (
        <Box
          sx={{
            padding: '1rem',
            background: theme.palette.background.default
          }}>
          <TitleLabel>Caja</TitleLabel>
        </Box>
      )}
      <Box
        sx={{
          marginBottom: '1.5rem',
          border: isLargeScreen
            ? `1px solid ${theme.palette.primary.main}`
            : 'none',
          padding: isLargeScreen ? '1rem' : '0,5rem',
          paddingBottom: '1rem',
          borderRadius: '0.625rem',
          background: theme.palette.background.default
        }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: '.5rem'
          }}>
          <Button
            onClick={() => setActiveButton(TabsEnum.NEW_CASHIER)}
            sx={{
              textTransform: 'none',
              fontSize: '1rem',
              marginRight: isLargeScreen ? undefined : '1rem',
              fontWeight: activeButton === TabsEnum.NEW_CASHIER ? 700 : 500,
              '&:hover': {
                backgroundColor: 'transparent',
                boxShadow: 'none'
              }
            }}>
            {isLargeScreen ? 'Alta de cajas' : 'Nueva'}
          </Button>
          <TitleLabel fontSize="1rem" fontWeight="500">
            |
          </TitleLabel>
          <Button
            onClick={() => setActiveButton(TabsEnum.ADMIN_CASHIER)}
            sx={{
              textTransform: 'none',
              fontSize: '1rem',
              marginLeft: isLargeScreen ? undefined : '1rem',
              fontWeight: activeButton === TabsEnum.ADMIN_CASHIER ? 700 : 500,
              '&:hover': {
                backgroundColor: 'transparent',
                boxShadow: 'none'
              }
            }}>
            {isLargeScreen ? 'Administración de caja' : 'Administracion'}
          </Button>
          <TitleLabel fontSize="1rem" fontWeight="500">
            |
          </TitleLabel>
          <Button
            onClick={() => setActiveButton(TabsEnum.RESTORE)}
            sx={{
              textTransform: 'none',
              fontSize: '1rem',
              marginLeft: isLargeScreen ? undefined : '1rem',
              fontWeight: activeButton === TabsEnum.RESTORE ? 700 : 500,
              '&:hover': {
                backgroundColor: 'transparent',
                boxShadow: 'none'
              }
            }}>
            {isLargeScreen ? 'Restaurar caja' : 'Restaurar'}
          </Button>
        </Box>

        {activeButton === TabsEnum.NEW_CASHIER && (
          <Grid
            container
            spacing={2}
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '.5rem'
            }}>
            <NewCashier formik={formik} />
          </Grid>
        )}
        {activeButton === TabsEnum.ADMIN_CASHIER && <AdminCash />}
        {activeButton === TabsEnum.RESTORE && <RestoreCashier />}
      </Box>
    </>
  );
};

export default CashRegistrationForm;
