import dayjs from 'dayjs';
import { formatDate } from 'helpers/DateTransformer';
import { useAppSelector } from 'hooks/useAppSelector';
import React from 'react';

import { Grid } from '@mui/material';

import Label from 'design-system/Label';

import { commerceSelector } from 'redux/commerce/commerce.selector';

interface CloseTransactionProp {
  TransactionsTotal: number;
  Currency: string;
}
export const TransactionsCloseLot: React.FC<CloseTransactionProp> = ({
  TransactionsTotal,
  Currency
}) => {
  const gridItemStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '0.75rem',
    fontWeight: 600
  };
  const now = new Date();
  const { commerce } = useAppSelector(commerceSelector);
  return (
    <Grid container spacing={1}>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: '0.75rem',
          fontWeight: 600,
          marginTop: '0.5rem'
        }}>
        <Label fontWeight={500}>Razón social</Label>
        <Label>{commerce ? commerce.businessName : '----'}</Label>
      </Grid>
      <Grid item xs={12} sx={gridItemStyle}>
        <Label fontWeight={500}>N° Afiliado</Label>
      </Grid>
      <Grid item xs={12} sx={gridItemStyle}>
        <Label fontWeight={500}>Fecha</Label>
        <Label>{formatDate(now)}</Label>
      </Grid>
      <Grid item xs={12} sx={gridItemStyle}>
        <Label fontWeight={500}>Hora</Label>
        <Label>{dayjs(now).format('HH:mm')}</Label>
      </Grid>
      <Grid item xs={12} sx={gridItemStyle}>
        <Label fontWeight={500}>Lote N°</Label>
        <Label>??</Label>
      </Grid>
      <Grid item xs={12} sx={gridItemStyle}>
        <Label fontWeight={500}>Moneda</Label>
        <Label>{Currency}</Label>
      </Grid>
      <Grid item xs={12} sx={gridItemStyle}>
        <Label fontWeight={500}>Total de transacciones</Label>
        <Label>{TransactionsTotal.toString()}</Label>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: '0.75rem',
          fontWeight: 600,
          marginBottom: '0.5rem'
        }}>
        <Label fontWeight={500}>Monto total</Label>
      </Grid>
    </Grid>
  );
};

export default TransactionsCloseLot;
