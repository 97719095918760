import { useAppDispatch } from 'hooks/useAppDispatch';
import React from 'react';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import RestoreIcon from '@mui/icons-material/Restore';
import { Box, Button, Typography, useTheme } from '@mui/material';

import {
  setCashierModalIsOpen,
  setCashierModalStatus,
  setSelectedCashier
} from 'redux/casher/casher.slice';
import { Cashier } from 'redux/casher/types/casher.type';
import { CashierModalStatus } from 'redux/casher/types/casherState.interface';

interface CashCardProp {
  cashIndex: string;
  casherInfo: Cashier;
  isDeleted?: boolean;
}
export const CashCard: React.FC<CashCardProp> = ({
  cashIndex,
  casherInfo,
  isDeleted
}) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        margin: '0.5rem',
        padding: '0.5rem',
        paddingLeft: '1rem',
        border: `1px solid ${theme.palette.primary.light}`,
        borderRadius: '0.5rem'
      }}>
      <Box
        sx={{
          display: 'flex',
          gap: '.5rem',
          alignItems: 'flex-end'
        }}>
        {`Caja ${cashIndex}: ${casherInfo.name}`}
        <Typography variant="body2" color={'#a1a1a1'}>
          {casherInfo.email}
        </Typography>
      </Box>
      <Box>
        {!isDeleted ? (
          <>
            <Button
              sx={{ marginRight: '-1rem' }}
              onClick={() => {
                dispatch(setCashierModalIsOpen(true));
                dispatch(setSelectedCashier(casherInfo));
                dispatch(setCashierModalStatus(CashierModalStatus.EDITING));
              }}>
              <ModeEditOutlineOutlinedIcon />
            </Button>
            <Button
              onClick={() => {
                dispatch(setCashierModalIsOpen(true));
                dispatch(setSelectedCashier(casherInfo));
                dispatch(setCashierModalStatus(CashierModalStatus.DELETING));
              }}>
              <DeleteOutlineIcon />
            </Button>
          </>
        ) : (
          <Button
            onClick={() => {
              dispatch(setCashierModalIsOpen(true));
              dispatch(setSelectedCashier(casherInfo));
              dispatch(setCashierModalStatus(CashierModalStatus.RESTORING));
            }}>
            <RestoreIcon />
          </Button>
        )}
      </Box>
    </Box>
  );
};
