import styled from '@emotion/styled';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React from 'react';

import {
  FormControl,
  InputLabel,
  MenuItem,
  Tooltip,
  useTheme
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface SelectorProps {
  Options: string[];
  Label: string;
  onValueChange: (value: string) => void;
  value: string;
  size?: string;
  disableNone?: boolean;
  disabled?: boolean;
  error?: boolean;
  tooltip?: string;
}

export const SelectorElement: React.FC<SelectorProps> = ({
  Options,
  Label,
  onValueChange,
  value,
  size = 'medium',
  disableNone,
  disabled,
  tooltip,
  error
}) => {
  const isLargeScreen = useIsLargeScreen();
  const theme = useTheme();
  const CustomSelect = styled(Select)({
    borderRadius: '0.625rem',
    background: theme.palette.background.default,
    color: theme.palette.primary.main,
    '&.MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: error
          ? theme.palette.error.main
          : theme.palette.primary.main
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main
      },
      '& .MuiInputBase-input': {
        color: theme.palette.primary.main
      }
    }
  });
  const handleOptionChange = (event: SelectChangeEvent<unknown>) => {
    const selectedValue = event.target.value as string;
    onValueChange(selectedValue);
  };

  return (
    <Tooltip title={tooltip}>
      <FormControl
        sx={{
          display: 'flex',
          width: '100%',
          fontSize: isLargeScreen ? undefined : 10
        }}
        size={isLargeScreen ? (size as 'small' | 'medium') : 'small'}
        disabled={disabled}>
        <InputLabel id="SelectorDesign-label">{Label}</InputLabel>
        <CustomSelect
          labelId="SelectorDesign-label"
          id="SelectorDesign"
          value={value}
          label={Label}
          placeholder={Label}
          onChange={handleOptionChange}>
          {!disableNone && (
            <MenuItem value="">
              <em>X</em>
            </MenuItem>
          )}
          {Options.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </CustomSelect>
      </FormControl>
    </Tooltip>
  );
};
