import { createTheme } from '@mui/material/styles';

const primary = {
  dark: '#204f3a',
  main: '#2F7154',
  light: '#588d76'
};

export const Colors = {
  white: '#FFFFFF',
  black: '#000000',
  transparentGrey: '#4a4747cc',
  fuzzy: '#2F7154F2',
  successful: '#03BB50',
  gray: '#494747',
  primary
};

const theme = createTheme({
  palette: {
    mode: 'light',
    primary
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          margin: '0.125rem 0',
          '& .MuiInputBase-input': {
            color: primary.main
          },
          '& .MuiFormHelperText-root': {
            width: '100%',
            margin: 0,
            color: 'red'
          },
          '& fieldset': {
            border: `1px solid ${primary.main}`,
            borderRadius: '0.5rem'
          },
          '& fieldset:hover': {
            border: `1px solid ${primary.dark}`
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '0.5rem'
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            fill: primary.main
          },
          '& .MuiTypography-root': {
            color: primary.main,
            fontSize: 12,
            fontWeight: 500
          }
        }
      }
    }
  },
  typography: {
    fontFamily: [
      '"Inter"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Poppins"',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  }
});

export default theme;
