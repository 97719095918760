import SidebarOptions from 'components/SidebarOptions';

import { SidebarOption } from 'design-system/Layouts/types/sidebarOptions.type';

const options: SidebarOption[] = [{ label: '', redirectTo: '', icon: <></> }];

interface IBuyerSideBarOptionsProps {
  onClick: () => void;
}

const BuyerSideBarOptions = ({ onClick }: IBuyerSideBarOptionsProps) => {
  return <SidebarOptions options={options} onClick={onClick} />;
};
export default BuyerSideBarOptions;
