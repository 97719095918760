import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React from 'react';

import { Box, useTheme } from '@mui/material';

interface PromitionProps {
  imageURL: string;
  index: number;
}
export const PromotionCard: React.FC<PromitionProps> = ({
  imageURL,
  index
}) => {
  const theme = useTheme();
  const descriptionImage = ('Promotion' + index) as string;
  const isLargeScreen = useIsLargeScreen();

  return (
    <Box
      sx={{
        width: isLargeScreen ? '18rem' : '15rem',
        height: isLargeScreen ? '18rem' : '15rem',
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: '0.75rem',
        background: theme.palette.background.default,
        overflow: 'hidden'
      }}>
      <img
        src={imageURL}
        alt={descriptionImage}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover'
        }}
      />
    </Box>
  );
};
