import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React from 'react';

import { Box, Container } from '@mui/material';

import TitleLabel from 'design-system/Layouts/TitleLabels';

import CloseLoteMobile from '../components/CloseLoteMobile';
import TransactionsTableLotClose from '../components/TransactionsTableLotClose';

export const TransactionsLotClose: React.FC = () => {
  const isLargeScreen = useIsLargeScreen();
  return (
    <>
      {isLargeScreen ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
          mt={'7.5rem'}>
          <Container fixed sx={{ minWidth: '90%' }}>
            <Box sx={{ marginBottom: '0.5rem' }}>
              <TitleLabel>Resumen de cierre de lote</TitleLabel>
            </Box>
            <TransactionsTableLotClose />
          </Container>
        </Box>
      ) : (
        <CloseLoteMobile />
      )}
    </>
  );
};
