import axios from 'axios';

import { CommerceEntity } from 'modules/Commerce/types/commerce.type';

import { IApiResponse } from 'types/responses/apiResponse.interface';
import { CommerceLoginResponse } from 'types/responses/commerceLogin.type';

import { setLocalStorage } from 'utils/localstorage';

import { api, baseURL } from './instance';

export const CommerceService = {
  login: async (params: loginRequestType) => {
    const res = await axios.post<IApiResponse<CommerceLoginResponse>>(
      `${baseURL}/commerce/login`,
      {
        email: params.email,
        password: params.password,
        affiliate: params.affiliate
      }
    );

    const token = res.data.data.token;
    setLocalStorage('token', token);

    return token;
  },
  getPromotions: async () => {
    const res = await api.get<IApiResponse<string>>(
      `${baseURL}/commerce/promotions`
    );

    return res.data;
  },
  getCommerceQR: async (id: string) => {
    const res = await api.get<IApiResponse<string>>(
      `${baseURL}/commerce/qr/${id}`
    );
    return res.data.data;
  },
  me: async () => {
    const res = await api.get<IApiResponse<CommerceEntity>>('/commerce/info');
    return res.data.data;
  }
};

export type loginRequestType = {
  email: string;
  password: string;
  affiliate: string;
  remember: boolean;
};

export type PasswordRequestType = {
  email: string;
  password: string;
};

export type forgotPassword = {
  email: string;
  user: string;
  affiliate: string;
};
