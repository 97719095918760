import React from 'react';

import { Grid, Skeleton } from '@mui/material';

export const CloseLoteSkeleton = () => {
  const gridItemStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '1.5rem',
    fontWeight: 600
  };
  return (
    <Grid container spacing={1}>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: '1.5rem',
          fontWeight: 600,
          marginTop: '0.5rem'
        }}>
        <Skeleton sx={{ width: '100%' }} />
      </Grid>
      <Grid item xs={12} sx={gridItemStyle}>
        <Skeleton sx={{ width: '100%' }} />
      </Grid>
      <Grid item xs={12} sx={gridItemStyle}>
        <Skeleton sx={{ width: '100%' }} />
      </Grid>
      <Grid item xs={12} sx={gridItemStyle}>
        <Skeleton sx={{ width: '100%' }} />
      </Grid>
      <Grid item xs={12} sx={gridItemStyle}>
        <Skeleton sx={{ width: '100%' }} />
      </Grid>
      <Grid item xs={12} sx={gridItemStyle}>
        <Skeleton sx={{ width: '100%' }} />
      </Grid>
      <Grid item xs={12} sx={gridItemStyle}>
        <Skeleton sx={{ width: '100%' }} />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: '1.5rem',
          fontWeight: 600,
          marginBottom: '0.5rem'
        }}>
        <Skeleton sx={{ width: '100%' }} />
      </Grid>
    </Grid>
  );
};
