import * as Yup from 'yup';

export const newCashSchema = Yup.object().shape({
  cashID: Yup.string()
    .required('Identificador de Caja requerido')
    .length(5, 'Debe contener 5 caracteres')
    .matches(
      /^(?=.*[0-9])[a-zA-Z0-9]+$/,
      'El nombre de la caja debe ser alfanumérico y contener al menos un número'
    ),
  password: Yup.string().required('Contraseña requerida').min(8, '8'),
  storeAddress: Yup.string().required('Dirección de la sucursal requerida'),
  location: Yup.string().required('Localidad requerida'),
  department: Yup.string().required('Departamento requerido'),
  email: Yup.string()
    .email('Correo electrónico inválido')
    .required('Correo electrónico requerido'),
  phoneNumber: Yup.string()
    .max(15, 'Debe contener 15 o menos caracteres')
    .required('Número de teléfono requerido')
});

export const editCashSchema = Yup.object().shape({
  cashID: Yup.string()
    .length(5, 'Debe contener 5 caracteres')
    .matches(
      /^(?=.*[0-9])[a-zA-Z0-9]+$/,
      'El nombre de la caja debe ser alfanumérico y contener al menos un número'
    ),
  password: Yup.string().min(
    8,
    'La contraseña debe tener al menos 8 caracteres'
  ),
  storeAddress: Yup.string(),
  location: Yup.string(),
  department: Yup.string(),
  email: Yup.string().email('Correo electrónico inválido'),
  phoneNumber: Yup.string()
    .max(15, 'Debe contener 15 o menos caracteres')
    .required('Número de teléfono requerido')
});

export const newCashInitialValues = {
  cashID: '',
  password: '',
  storeAddress: '',
  location: '',
  department: '',
  email: '',
  phoneNumber: ''
};
