export const printLotCloseTable = (tableContent: string) => {
  const printWindow = window.open('', '_blank');
  if (printWindow && printWindow.document) {
    printWindow.document.write(
      '<html><head><title>Cierre de Lote</title></head><body>'
    );
    printWindow.document.write('<style>');
    printWindow.document.write('@page { size: auto; margin: 20mm; }');
    printWindow.document.write('body { font-family: Arial, sans-serif; }');
    printWindow.document.write(
      'table { width: 100%; border-collapse: collapse; border-radius: 25px; background-color: #f9f9f9; }'
    ); // Example background color
    printWindow.document.write(
      'th, td { border: 1px solid black; text-align: center; padding: 10px; }'
    ); // Added border-radius and padding
    printWindow.document.write('th { background-color: #e0e0e0; }');
    printWindow.document.write('</style>');
    printWindow.document.write(tableContent);
    printWindow.document.write('</body></html>');
    printWindow.document.close();

    setTimeout(() => {
      printWindow.print();
    }, 250);
  }
};
