import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, CircularProgress, useTheme } from '@mui/material';

import { Colors } from 'design-system/theme';

import { getTransactions } from 'redux/transaction/transaction.action';
import { transactionSelector } from 'redux/transaction/transaction.selector';

import { TransactionMobileElement } from './TransactionMobileElement';
import TransactionsFiltersMobile from './TransactionsFiltersMobile';

export const TransactionsTableMobile: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { transactions, pagination, loading } =
    useAppSelector(transactionSelector);

  const location = useLocation();

  useEffect(() => {
    dispatch(getTransactions(queryString.parse(location.search)));
  }, [dispatch, location.search]);

  useEffect(() => {
    if (transactions.length === 0 && pagination.totalPages) {
      navigate({
        search: `${queryString.stringify({
          ...queryString.parse(location.search),
          page: pagination.totalPages
        })}`
      });
    }
  }, [location.search, navigate, pagination.totalPages, transactions.length]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '65vh'
        }}>
        <Box sx={{ magin: 0 }}>
          <TransactionsFiltersMobile />
        </Box>
        <Box
          sx={{
            maxHeight: '400px',
            overflowY: 'auto',
            backgroundColor: theme.palette.background.paper,
            '&::-webkit-scrollbar': {
              width: '0.25rem',
              height: '0.5rem'
            },
            '&::-webkit-scrollbar-track': {
              background: theme.palette.background.paper
            },
            '&::-webkit-scrollbar-thumb': {
              background: Colors.fuzzy,
              borderRadius: '10px'
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: '#555'
            },
            scrollbarWidth: 'thin',
            scrollbarColor: `${Colors.primary} ${theme.palette.background.paper}`
          }}>
          {loading ? (
            <Box
              sx={{
                height: '10rem',
                width: '100%',
                margin: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              {transactions &&
                transactions.length > 0 &&
                transactions.map((transaction) => (
                  <Box
                    key={transaction.id}
                    sx={{
                      margin: '0.5rem',
                      borderTop: `1px solid ${theme.palette.primary.light}`
                    }}>
                    <TransactionMobileElement transaction={transaction} />
                  </Box>
                ))}
            </>
          )}
        </Box>
      </Box>
    </>
  );
};
