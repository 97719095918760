import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Badge, Button, Grid, Tooltip } from '@mui/material';

import { setModalOpen } from 'redux/commerce/commerce.slice';
import { getPendingTransactions } from 'redux/transaction/transaction.action';
import { transactionSelector } from 'redux/transaction/transaction.selector';
import { userSelector } from 'redux/user/user.selector';

import Roles from 'types/roles.enum';

import CommerceRoutes from '../types/routes.enum';

export const CommerceNavegation: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isLargeScreen = useIsLargeScreen();
  const { role } = useAppSelector(userSelector);
  const { pendingTransactions } = useAppSelector(transactionSelector);

  useEffect(() => {
    dispatch(getPendingTransactions());
  }, [dispatch]);

  const buttonStyles = {
    minHeight: '5rem',
    fontSize: isLargeScreen ? '1rem' : '0.7rem',
    fontWeight: '600',
    fontFamily: 'Poppins',
    minWidth: '7.5rem'
  };

  return (
    <Grid
      spacing={1}
      container
      sx={{
        marginTop: '0.5rem',
        // marginLeft: isLargeScreen ? undefined : '0.75rem',
        justifyContent: 'space-between'
      }}>
      <Grid item xs={6} lg={'auto'}>
        <Button
          fullWidth
          variant={isLargeScreen ? 'contained' : 'outlined'}
          sx={buttonStyles}
          onClick={() => {
            navigate(`/commerce/${CommerceRoutes.SELL}`);
          }}>
          Nueva Venta
        </Button>
      </Grid>
      <Grid item xs={6} lg={'auto'}>
        <Button
          fullWidth
          variant={isLargeScreen ? 'contained' : 'outlined'}
          sx={buttonStyles}
          onClick={() => dispatch(setModalOpen(true))}>
          Ver QR
        </Button>
      </Grid>
      <Grid item xs={6} lg={'auto'}>
        {/* prettier-ignore */}
        <Badge
          badgeContent={
            pendingTransactions.length > 0 ? (
              <Tooltip
                title="Tiene transacciones pendientes de aprobación"
                placement="right-start">
                <span>{pendingTransactions.length}</span>
              </Tooltip>
            ) : 0
          }
          color="warning"
          sx={{
            '& .MuiBadge-badge': {
              fontSize: 20,
              height: 30,
              minWidth: 30,
              borderRadius: 4
            }
          }}>
          <Button
            variant={isLargeScreen ? 'contained' : 'outlined'}
            fullWidth
            sx={{
              minHeight: '5rem',
              fontSize: isLargeScreen ? '1rem' : '0.7rem',
              fontWeight: '600',
              fontFamily: 'Poppins'
            }}
            onClick={() => {
              navigate(`/commerce/${CommerceRoutes.TRANSACTIONS}`);
            }}>
            Historial de transacciones
          </Button>
        </Badge>
      </Grid>
      {role === Roles.ADMIN_COMMERCE && (
        <Grid item xs={6} lg={'auto'}>
          <Button
            variant={isLargeScreen ? 'contained' : 'outlined'}
            fullWidth
            sx={{
              minHeight: '5rem',
              fontSize: isLargeScreen ? '1rem' : '0.7rem',
              fontWeight: '600',
              fontFamily: 'Poppins'
            }}
            onClick={() => {
              navigate(`/commerce/${CommerceRoutes.CASH_REGISTER_CREATION}`);
            }}>
            Alta de usuario caja
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
