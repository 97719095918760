import { ReactComponent as Dots } from 'assets/imgs/dots.svg';

import { Box } from '@mui/material';

const BackgroundDots = () => {
  return (
    <>
      <Box
        sx={{
          maxWidth: '9.25rem',
          margin: 'auto',
          marginBottom: '4.25rem',
          position: 'fixed',
          right: 0,
          top: '33%',
          zIndex: -1
        }}>
        <Dots />
      </Box>
      <Box
        sx={{
          maxWidth: '9.25rem',
          marginBottom: '4.25rem',
          position: 'fixed',
          left: '-1%',
          bottom: '-5.625rem',
          zIndex: -1
        }}>
        <Dots />
      </Box>
      <Box
        sx={{
          maxWidth: '9.25rem',
          margin: 'auto',
          marginBottom: '4.25rem',
          position: 'fixed',
          left: '9rem',
          bottom: '-0.75rem',
          zIndex: -1
        }}>
        <Dots />
      </Box>
    </>
  );
};
export default BackgroundDots;
