import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import Roles from 'types/roles.enum';

import { IUserState } from './types/userState.interface';

const initialState: IUserState = {
  role: Roles.UNKNOW
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserRole: (state, action: PayloadAction<Roles>) => {
      state.role = action.payload;
    }
  }
});

export const { setUserRole } = userSlice.actions;

export default userSlice.reducer;
