import { Form, Formik } from 'formik';
import wait from 'helpers/wait';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  newTransactionInitialValues,
  newTransactionSchema
} from 'schemas/newTransaction';

import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useTheme
} from '@mui/material';

import ModalApprove from 'components/ModalApproved';
import ModalLoader from 'components/ModalLoader';
import ModalReject from 'components/ModalRejected';
import { NewsPromotions } from 'components/NewsPromotions';

import Modal from 'design-system/Modal';

import { me } from 'redux/commerce/commerce.action';
import { commerceSelector } from 'redux/commerce/commerce.selector';
import {
  getTransactionById,
  transactionRequestAction
} from 'redux/transaction/transaction.action';
import { transactionSelector } from 'redux/transaction/transaction.selector';
import {
  cleanTransactionModal,
  cleanValidatedCi,
  setTransactionModalIsOpen,
  setTransactionModalStatus
} from 'redux/transaction/transaction.slice';
import { TransactionModalStatus } from 'redux/transaction/types/transactionState.interface';

import { CommerceData } from '../components/CommerceData';
import TransactionForm from '../components/TransactionForm';
import CommerceRoutes from '../types/routes.enum';

export const SellPage: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isLargeScreen = useIsLargeScreen();

  const { transactionModal, errorMessage, validatedCi, isGettingCiInfo } =
    useAppSelector(transactionSelector);
  const { qrImage } = useAppSelector(commerceSelector);

  useEffect(() => {
    dispatch(me());
  }, [dispatch]);

  const handleCancel = () => {
    navigate(`/commerce/${CommerceRoutes.HOME}`);
  };

  const handleSubmit = async (values: any, formikHelpers: any) => {
    if (!validatedCi) {
      formikHelpers.setErrors({ customerID: 'Cédula inválida' });
      return;
    }

    const TransactionLoad = {
      document: String(values.customerID).slice(0, 7),
      name: `${
        validatedCi.primerNombre ? validatedCi.primerNombre.trim() : ''
      } ${validatedCi.segundoNombre ? validatedCi.segundoNombre.trim() : ''} ${
        validatedCi.primerApellido ? validatedCi.primerApellido.trim() : ''
      } ${validatedCi.segundoApellido ? validatedCi.segundoApellido.trim() : ''}
      `, //String(values.nameAndLastname),
      transactionNumber: String(values.transactionNumber),
      currency: values.currency,
      dues: Math.abs(Number(values.dues)),
      amount: Math.abs(Number(values.amount))
    };

    const resp = await dispatch(transactionRequestAction(TransactionLoad));

    if (resp.meta.requestStatus === 'fulfilled') {
      dispatch(setTransactionModalStatus(TransactionModalStatus.WAITING));
      dispatch(cleanValidatedCi());
    }
  };

  const onModalClose = () => {
    dispatch(setTransactionModalIsOpen(false));
    dispatch(cleanTransactionModal());
  };

  useEffect(() => {
    const updateTransaction = async () => {
      if (
        typeof transactionModal.counter === 'number' &&
        !!transactionModal.transaction
      ) {
        await wait(750);
        dispatch(getTransactionById(transactionModal.transaction.id));
      }
    };
    updateTransaction();
  }, [transactionModal.counter, dispatch, transactionModal.transaction]);

  return (
    <>
      <Modal
        open={transactionModal.isOpen}
        onClose={() => {
          onModalClose();
        }}
        displayClose={
          transactionModal.status === TransactionModalStatus.WAITING
        }
        {...(transactionModal.status === TransactionModalStatus.WAITING
          ? {
              modalWidth: '28rem',
              modalHeight: '31rem'
            }
          : {})}>
        {transactionModal.status === TransactionModalStatus.WAITING && (
          <ModalLoader
            LoaderLabel="Esperando Autorización"
            qrImageURL={qrImage}
            showCloseButton={true}
            handleClose={() => {
              onModalClose();
              navigate(`/commerce/${CommerceRoutes.HOME}`);
            }}
          />
        )}
        {transactionModal.status === TransactionModalStatus.APPROVED && (
          <ModalApprove
            handleClose={() => {
              onModalClose();
              navigate(`/commerce/${CommerceRoutes.HOME}`);
            }}
            ApproveLabel="Operación autorizada"
          />
        )}
        {transactionModal.status === TransactionModalStatus.SENT && (
          <ModalApprove ApproveLabel="Operación solicitada" />
        )}
        {transactionModal.status ===
          TransactionModalStatus['REJECTED-BY-BUYER'] && (
          <ModalReject
            rejectLabel="Operación rechazada por el cliente"
            handleClose={() => {
              onModalClose();
            }}
          />
        )}
        {transactionModal.status ===
          TransactionModalStatus['REJECTED-BY-CD'] && (
          <ModalReject
            rejectLabel="Operación rechazada por Créditos Directos"
            handleClose={() => {
              onModalClose();
            }}
          />
        )}
        {transactionModal.status === TransactionModalStatus.INVALID_CI && (
          <ModalReject
            rejectLabel={
              'El documento ingresado no pertenece \na un cliente de Creditos Directos'
            }
            handleClose={() => {
              onModalClose();
            }}
            buttonLabel="Cerrar"
          />
        )}
      </Modal>
      <Container
        maxWidth="lg"
        sx={{
          pb: '2rem',
          mt: isLargeScreen ? '7.5rem' : '6rem'
        }}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing={isLargeScreen ? 2 : 1}
          sx={{
            minHeight: '75vh'
          }}>
          <Grid
            item
            xs={12}
            sx={{
              marginBottom: '1rem',
              background: theme.palette.background.default,
              margin: isLargeScreen ? undefined : '0.5rem'
            }}>
            <NewsPromotions />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              borderTop: `1px solid ${theme.palette.primary.main}`,
              borderBottom: `1px solid ${theme.palette.primary.main}`,
              backgroundColor: theme.palette.background.default,
              margin: isLargeScreen ? undefined : '0.5rem'
            }}>
            <CommerceData />
          </Grid>
          <Grid item xs={12} my={isLargeScreen ? undefined : '0.5rem'}>
            <Formik
              initialValues={newTransactionInitialValues}
              onSubmit={(values, formikHelpers) =>
                handleSubmit(values, formikHelpers)
              }
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={newTransactionSchema}>
              {(formik) => (
                <Form>
                  <TransactionForm formik={formik} />
                  {errorMessage && (
                    <Box bgcolor={theme.palette.background.paper} m="auto">
                      <Typography variant="body1" color="error">
                        {errorMessage}
                      </Typography>
                    </Box>
                  )}
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginBottom: '1.5rem',
                      gap: '1rem',
                      fontSize: isLargeScreen ? undefined : 12
                    }}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      disabled={formik.isSubmitting || isGettingCiInfo}>
                      Confirmar
                    </Button>
                    <Button
                      type="submit"
                      fullWidth
                      variant="outlined"
                      onClick={handleCancel}
                      sx={{
                        fontSize: isLargeScreen ? undefined : 12
                      }}>
                      Cancelar
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default SellPage;
