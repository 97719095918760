import { useAppSelector } from 'hooks/useAppSelector';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Grid, Switch, Typography, useTheme } from '@mui/material';

import SearchBar from 'components/SearchBar';
import { statusMapping } from 'components/TransactionsFilters';

import TitleLabel from 'design-system/Layouts/TitleLabels';
import { SelectorElement } from 'design-system/Selector';
import { Colors } from 'design-system/theme';

import { MuiSizes } from 'modules/Commerce/types/sizes.enum';

import { userSelector } from 'redux/user/user.selector';

import {
  GetTransactionsQueryParams,
  SortOrderEnum,
  SortTransferByEnum
} from 'types/request/transactionCommerce.interface';
import Roles from 'types/roles.enum';

import { updateGetTransactionsSortBy } from 'utils/updateSortBy';

const transactionNumberOptions = ['Factura asc', 'Factura desc'];
const buyerCIOptions = ['Cédula asc', 'Cédula desc'];

const TransactionsFiltersMobile = () => {
  const theme = useTheme();
  const isLargeScreen = useIsLargeScreen();
  const location = useLocation();
  const navigate = useNavigate();
  const { role } = useAppSelector(userSelector);

  const isCommerce = [Roles.ADMIN_COMMERCE, Roles.COMMERCE].includes(role);

  const [transactionSort, setTransactionSort] = useState<string>('');
  const [documentSort, setDocumentSort] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [allCashiers, setAllCashiers] = useState(false);

  const handleTransactionSortChange = (value: string) => {
    if (value === '') setTransactionSort('');
    const cp = updateGetTransactionsSortBy({
      value,
      location,
      sortBy: SortTransferByEnum.TRANSACTION_NUMBER
    });
    navigate({ search: queryString.stringify(cp) });
  };

  const handleDocumentSortChange = (value: string) => {
    if (value === '') setTransactionSort('');
    const cp = updateGetTransactionsSortBy({
      value,
      location,
      sortBy: SortTransferByEnum.BUYER_CI
    });
    navigate({ search: queryString.stringify(cp) });
  };

  const handleStatusChange = (value: string) => {
    const cp = queryString.parse(location.search) as GetTransactionsQueryParams;
    if (value === '' && cp.status) {
      delete cp.status;
    } else {
      cp.status = statusMapping[value];
    }
    navigate({ search: queryString.stringify(cp) });
  };

  const onSearch = (search: string) => {
    const cp = queryString.parse(location.search) as GetTransactionsQueryParams;
    if (search === '' && cp.term) {
      delete cp.term;
    } else {
      cp.term = search;
    }
    navigate({ search: queryString.stringify(cp) });
  };

  const handleAllCashierChange = (value: boolean) => {
    const cp = queryString.parse(location.search) as GetTransactionsQueryParams;
    if (value && cp.all) {
      delete cp.all;
    } else {
      cp.all = false;
    }
    navigate({ search: queryString.stringify(cp) });
  };

  useEffect(() => {
    const cp = queryString.parse(location.search) as GetTransactionsQueryParams;
    if (cp.sortBy === SortTransferByEnum.TRANSACTION_NUMBER) {
      if (cp.sortOrder === SortOrderEnum.ASC) {
        setTransactionSort(transactionNumberOptions[0]);
      } else if (cp.sortOrder === SortOrderEnum.DESC) {
        setTransactionSort(transactionNumberOptions[1]);
      }
      setDocumentSort('');
    } else if (cp.sortBy === SortTransferByEnum.BUYER_CI) {
      if (cp.sortOrder === SortOrderEnum.ASC) {
        setDocumentSort(buyerCIOptions[0]);
      } else if (cp.sortOrder === SortOrderEnum.DESC) {
        setDocumentSort(buyerCIOptions[1]);
      }
      setTransactionSort('');
    } else {
      setTransactionSort('');
      setDocumentSort('');
    }
    if (!!cp.status) {
      const statusFilter = Object.keys(statusMapping).find(
        (key) => statusMapping[key] === cp.status
      );
      setStatus(statusFilter ?? '');
    } else {
      setStatus('');
    }

    setAllCashiers(!cp.all);
  }, [location.search]);

  return (
    <Box
      sx={{
        marginBottom: '1rem'
      }}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid
          item
          xs={12}
          md={'auto'}
          display="flex"
          alignItems="center"
          gap=".5rem">
          <SearchBar
            onSearch={onSearch}
            value={
              !!queryString.parse(location.search).term
                ? (queryString.parse(location.search).term as string)
                : ''
            }
            placeholder={isCommerce ? 'Buscar por Factura o CI' : undefined}
          />
          {role === Roles.ADMIN_COMMERCE && (
            <Box display="flex" alignItems="center">
              <Typography
                alignSelf="center"
                mr={1}
                sx={{
                  color: Colors.gray,
                  fontFamily: 'Poppins',
                  fontSize: '0.75rem'
                }}>
                Mostrar todas las cajas
              </Typography>
              <Switch
                defaultChecked={
                  !(
                    queryString.parse(
                      location.search
                    ) as GetTransactionsQueryParams
                  ).all
                }
                size="small"
                value={allCashiers}
                onChange={(e) => handleAllCashierChange(e.target.checked)}
              />
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={'auto'}>
          <Grid container spacing={1} alignItems="center" justifyContent="end">
            <Grid item xs={12} md={'auto'}>
              <TitleLabel fontSize={isLargeScreen ? undefined : '0.75rem'}>
                Ordenar por:
              </TitleLabel>
            </Grid>

            <Grid item xs={12} sm={6} md={'auto'} sx={{ minWidth: '10.5rem' }}>
              <SelectorElement
                Options={transactionNumberOptions}
                Label={'Nro de Factura'}
                onValueChange={handleTransactionSortChange}
                value={transactionSort}
                size={MuiSizes.SMALL}
              />
            </Grid>

            {isCommerce && (
              <Grid
                item
                xs={12}
                sm={isCommerce ? 6 : 12}
                md={'auto'}
                sx={{ minWidth: '9rem' }}>
                <SelectorElement
                  Options={buyerCIOptions}
                  Label={'Cédula'}
                  onValueChange={handleDocumentSortChange}
                  value={documentSort}
                  size={MuiSizes.SMALL}
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sm={6}
              md={'auto'}
              sx={{
                minWidth: '7.5rem',
                [theme.breakpoints.up('md')]: {
                  marginLeft: '.5rem',
                  borderLeft: `1px solid ${theme.palette.primary.light}`
                }
              }}>
              <SelectorElement
                Options={Object.keys(statusMapping)}
                Label={'Estado'}
                onValueChange={handleStatusChange}
                value={status}
                size={MuiSizes.SMALL}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TransactionsFiltersMobile;
