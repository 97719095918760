import { Grid, Skeleton, useTheme } from '@mui/material';

const CashierCardLoader: React.FC = () => {
  const theme = useTheme();
  return (
    <Grid
      item
      xs={12}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '0.5rem',
        padding: '0.5rem',
        paddingLeft: '1rem',
        border: `1px solid ${theme.palette.primary.light}`,
        borderRadius: '0.5rem'
      }}>
      <Skeleton variant="text" width="40%" />
      <div>
        <Skeleton width={44} height={24} sx={{ marginRight: '1rem' }} />
      </div>
    </Grid>
  );
};

export default CashierCardLoader;
