import dayjs from 'dayjs';
import { formatDate } from 'helpers/DateTransformer';
import { printLotCloseTable } from 'helpers/LotClosePrinterHelper';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  Skeleton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
  useTheme
} from '@mui/material';

import { Colors } from 'design-system/theme';

import { me as meCashier } from 'redux/casher/casher.action';
import { CashierSelector } from 'redux/casher/casher.selector';
import { me } from 'redux/commerce/commerce.action';
import { commerceSelector } from 'redux/commerce/commerce.selector';
import {
  closeTransactions,
  getForCloseTransactions
} from 'redux/transaction/transaction.action';
import { transactionSelector } from 'redux/transaction/transaction.selector';
import Currency, {
  Transaction
} from 'redux/transaction/types/transaction.type';
import { userSelector } from 'redux/user/user.selector';

import Roles from 'types/roles.enum';

const tableStyles = {
  border: 'none',
  flex: 1,
  color: Colors.gray
};

const StyledTableCell = styled(TableCell)(() => ({
  whiteSpace: 'nowrap',
  width: 'max-content',
  [`&.${tableCellClasses.head}`]: {
    ...tableStyles,
    fontSize: 14,
    fontWeight: 600
  },
  [`&.${tableCellClasses.body}`]: {
    ...tableStyles,
    fontSize: 14,
    fontWeight: 600
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  margin: 0,
  padding: '0',
  borderRadius: '0.5rem',
  border: `1px solid ${theme.palette.primary.main}`,
  justifyContent: 'space-around'
}));

const StyledTableRowBody = styled(TableRow)({
  display: 'flex',
  width: '100%',
  margin: 0,
  padding: '0'
});

const TransactionsTableLotClose: React.FC = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const now = new Date();
  const navigate = useNavigate();

  const [allCashiers, setAllCashiers] = useState(true);

  const { transactionsForClose, loading } = useAppSelector(transactionSelector);
  const { me: cashierData } = useAppSelector(CashierSelector);
  const { commerce } = useAppSelector(commerceSelector);
  const { role } = useAppSelector(userSelector);

  const USDTransactions: Transaction[] = [];
  let USDTransactionsTotal: number = 0;
  const UYUTransactions: Transaction[] = [];
  let UYUTransactionsTotal: number = 0;

  if (transactionsForClose.length > 0)
    transactionsForClose.forEach((transaction) => {
      if (transaction.currency === Currency.UYU) {
        UYUTransactions.push(transaction);
        UYUTransactionsTotal = UYUTransactionsTotal + transaction.amount;
      } else if (transaction.currency === Currency.USD) {
        USDTransactions.push(transaction);
        USDTransactionsTotal = USDTransactionsTotal + transaction.amount;
      }
    });

  const handleCloseLot = async () => {
    const resp = await dispatch(
      closeTransactions([
        ...USDTransactions.map((t) => t.id),
        ...UYUTransactions.map((t) => t.id)
      ])
    );
    if (resp.meta.requestStatus === 'fulfilled') navigate(-1);
  };

  const handlePrint = () => {
    const table = document.querySelector('table');
    if (table) {
      printLotCloseTable(table.outerHTML);
    }
  };

  useEffect(() => {
    dispatch(me());
    if (role === Roles.COMMERCE) dispatch(meCashier());
  }, [dispatch, role]);

  useEffect(() => {
    dispatch(
      getForCloseTransactions({
        forAll: role === Roles.COMMERCE ? false : allCashiers
      })
    );
  }, [dispatch, allCashiers, role]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.paper
      }}>
      {role === Roles.ADMIN_COMMERCE && (
        <Box display="flex" alignItems="center" mb="1rem">
          <Typography
            alignSelf="center"
            mr={1}
            sx={{
              color: Colors.gray,
              fontFamily: 'Poppins',
              fontSize: '0.75rem'
            }}>
            Cerrar todas las cajas
          </Typography>
          <Switch
            defaultChecked
            size="small"
            value={allCashiers}
            onChange={() => setAllCashiers(!allCashiers)}
          />
        </Box>
      )}
      <Typography
        sx={{
          color: theme.palette.primary.main,
          fontFamily: 'Poppins',
          fontSize: '1.25rem',
          fontWeight: 700,
          mb: 1
        }}>
        {role === Roles.ADMIN_COMMERCE
          ? allCashiers
            ? 'Todas las cajas'
            : 'Solo mi caja'
          : cashierData?.name}
      </Typography>
      <TableContainer sx={{ overflowY: 'auto', flexGrow: 1, minWidth: '100%' }}>
        <Table aria-label="Transaction-tables" sx={{ minWidth: '100%' }}>
          <TableHead>
            <StyledTableRow>
              {[
                'Razon social',
                'Nro Afiliado',
                'Fecha',
                'Hora',
                'Lote Nro.',
                'Total de transacciones',
                'Monto total'
              ].map((header) => (
                <StyledTableCell
                  key={header}
                  align="center"
                  sx={{ width: header === 'Factura' ? '18rem' : 'auto' }}>
                  {header}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {!loading ? (
              <>
                {USDTransactions.length > 0 && (
                  <StyledTableRowBody>
                    <StyledTableCell align="center">
                      {commerce?.businessName}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {commerce?.commercialCode}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {formatDate(now)}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {dayjs(now).format('HH:mm')}
                    </StyledTableCell>
                    <StyledTableCell align="center">??</StyledTableCell>

                    <StyledTableCell align="center">
                      {USDTransactions.length}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      USD {USDTransactionsTotal}
                    </StyledTableCell>
                  </StyledTableRowBody>
                )}
                {UYUTransactions.length > 0 && (
                  <StyledTableRowBody>
                    <StyledTableCell align="center">
                      {/* {formatDate(transaction.createdAt)} */}
                      {commerce?.businessName}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {commerce?.commercialCode}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {formatDate(now)}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {dayjs(now).format('HH:mm')}
                    </StyledTableCell>
                    <StyledTableCell align="center">??</StyledTableCell>

                    <StyledTableCell align="center">
                      {UYUTransactions.length}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      $ {UYUTransactionsTotal}
                    </StyledTableCell>
                  </StyledTableRowBody>
                )}
              </>
            ) : (
              Array(2)
                .fill(1)
                .map((a, i) => (
                  <StyledTableRowBody key={i} sx={{}}>
                    <StyledTableCell align="center">
                      <Skeleton sx={{ fontSize: '0.875rem' }} />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Skeleton sx={{ fontSize: '0.875rem' }} />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Skeleton sx={{ fontSize: '0.875rem' }} />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Skeleton sx={{ fontSize: '0.875rem' }} />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Skeleton sx={{ fontSize: '0.875rem' }} />
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      <Skeleton sx={{ fontSize: '0.875rem' }} />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Skeleton sx={{ fontSize: '0.875rem' }} />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Skeleton sx={{ fontSize: '0.875rem' }} />
                    </StyledTableCell>
                  </StyledTableRowBody>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {(USDTransactions.length > 0 || UYUTransactions.length > 0) && (
        <Box
          sx={{
            display: 'flex',
            gap: '1rem',
            alignItems: 'center',
            mt: 2,
            mb: 1
          }}>
          <Button onClick={handleCloseLot} variant="contained">
            Cerrar lote
          </Button>
          <Button onClick={handlePrint} variant="outlined">
            Imprimir resumen
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default TransactionsTableLotClose;
