import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { styled } from '@mui/system';

import { Colors } from 'design-system/theme';

const StyledFormControl = styled('form')({
  margin: '0'
});

const CustomTextField = styled(TextField)({
  '& .MuiInputBase-root': {
    fontSize: '11px'
  }
});
interface SearchVariables {
  onSearch: (searchTerm: string) => void;
  value: string;
  placeholder?: string;
}
export const SearchBar: React.FC<SearchVariables> = ({
  onSearch,
  value,
  placeholder = 'Buscar por Factura'
}) => {
  const isLargeScreen = useIsLargeScreen();
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
    onSearch(event.target.value);
  };

  const handleClear = (): void => {
    onSearch('');
  };

  return (
    <StyledFormControl onSubmit={(e) => e.preventDefault()}>
      <CustomTextField
        size="small"
        variant="outlined"
        placeholder={placeholder}
        name="searcher"
        type="text"
        value={value}
        onChange={handleSearch}
        sx={{ width: isLargeScreen ? undefined : '100%' }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon sx={{ color: Colors.fuzzy }} />
            </InputAdornment>
          ),
          endAdornment: !!value && (
            <InputAdornment position="end">
              <IconButton size="small" onClick={handleClear}>
                <ClearIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    </StyledFormControl>
  );
};

export default SearchBar;
