export enum userType {
  ADMIN = 'admin',
  CASHIER = 'CASHIER',
  CLIENT = 'client'
}

export interface IJwtPayload {
  userId: string;
  authType: IAuthType;
  type: string;
  iat: number;
  exp: number;
}

export enum IAuthType {
  ADMIN = 'admin',
  CASHIER = 'cashier',
  BUYER = 'buyer'
}
