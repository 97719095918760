import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React from 'react';

import { Box, Button } from '@mui/material';

import Label from 'design-system/Label';
import { Colors } from 'design-system/theme';

interface ApproveProps {
  ApproveLabel: string;
  handleClose?: () => void;
  buttonText?: string;
}

const ModalApprove: React.FC<ApproveProps> = ({
  ApproveLabel,
  handleClose,
  buttonText
}) => {
  const isLargeScreen = useIsLargeScreen();
  return (
    <Box
      sx={{
        width: isLargeScreen ? undefined : '16rem',
        height: isLargeScreen ? undefined : '20rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '2rem'
      }}>
      <svg width="102" height="101" viewBox="0 0 102 101" fill="none">
        <path
          d="M69.8084 40.9749C71.1752 39.608 71.1752 37.392 69.8084 36.0251C68.4415 34.6583 66.2255 34.6583 64.8586 36.0251L44.0002 56.8836L37.1417 50.0251C35.7749 48.6583 33.5588 48.6583 32.192 50.0251C30.8251 51.392 30.8251 53.608 32.192 54.9749L41.5253 64.3082C42.8921 65.675 45.1082 65.675 46.475 64.3082L69.8084 40.9749Z"
          fill={Colors.successful}
        />
        <path
          d="M51.0002 0C23.2939 0 0.833496 22.4604 0.833496 50.1667C0.833496 77.873 23.2939 100.333 51.0002 100.333C78.7065 100.333 101.167 77.873 101.167 50.1667C101.167 22.4604 78.7065 0 51.0002 0ZM7.8335 50.1667C7.8335 26.3264 27.1599 7 51.0002 7C74.8405 7 94.1668 26.3264 94.1668 50.1667C94.1668 74.007 74.8405 93.3333 51.0002 93.3333C27.1599 93.3333 7.8335 74.007 7.8335 50.1667Z"
          fill={Colors.successful}
        />
      </svg>
      <Label fontSize={20} fontWeight={600}>
        {ApproveLabel}
      </Label>
      {handleClose && (
        <Button
          fullWidth
          variant="contained"
          onClick={handleClose}
          sx={{ width: '21rem', maxWidth: '100%' }}>
          {buttonText ? buttonText : 'Terminar'}
        </Button>
      )}
    </Box>
  );
};

export default ModalApprove;
