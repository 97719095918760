import { useAppSelector } from 'hooks/useAppSelector';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Grid, InputAdornment, TextField } from '@mui/material';

import Label from 'design-system/Label';
import { SelectorElement } from 'design-system/Selector';

import { CashierSelector } from 'redux/casher/casher.selector';

import uruguayanCities from 'utils/uruguayanCities';

import CommerceRoutes from '../types/routes.enum';
import { CashRegristrationProps } from './CashierRegistrationForm';

export const NewCashier: React.FC<CashRegristrationProps> = ({ formik }) => {
  const navigate = useNavigate();
  const isLargeScreen = useIsLargeScreen();
  const { errorMessage } = useAppSelector(CashierSelector);

  const handleCancel = () => {
    navigate(`/commerce/${CommerceRoutes.HOME}`);
  };

  return (
    <>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label=""
          size={isLargeScreen ? undefined : 'small'}
          sx={{
            margin: 0
          }}
          value={formik.values.cashID}
          onChange={(e) => formik.setFieldValue('cashID', e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                sx={{ fontSize: isLargeScreen ? undefined : '8' }}>
                Caja:
              </InputAdornment>
            )
          }}
          helperText={formik.errors.cashID}
          error={!!formik.errors.cashID}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label=""
          fullWidth
          size={isLargeScreen ? undefined : 'small'}
          sx={{
            margin: 0,
            fontSize: isLargeScreen ? undefined : '10'
          }}
          value={formik.values.email}
          onChange={(e) => formik.setFieldValue('email', e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                sx={{ fontSize: isLargeScreen ? undefined : '8' }}>
                Correo Electrónico:
              </InputAdornment>
            )
          }}
          error={!!formik.errors.email}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label=""
          size={isLargeScreen ? undefined : 'small'}
          sx={{ width: '100%', margin: 0 }}
          value={formik.values.password}
          helperText={formik.errors.password}
          onChange={(e) => formik.setFieldValue('password', e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                sx={{ fontSize: isLargeScreen ? undefined : '8' }}>
                Contraseña:
              </InputAdornment>
            )
          }}
          error={!!formik.errors.password}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label=""
          size={isLargeScreen ? undefined : 'small'}
          sx={{ width: '100%', margin: 0 }}
          value={formik.values.storeAddress}
          helperText={formik.errors.storeAddress}
          onChange={(e) => formik.setFieldValue('storeAddress', e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                sx={{ fontSize: isLargeScreen ? undefined : '8' }}>
                Dirección de sucursal:
              </InputAdornment>
            )
          }}
          error={!!formik.errors.storeAddress}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <SelectorElement
          Options={uruguayanCities.map((city) => city.name)}
          Label="Departamento"
          onValueChange={(value) => formik.setFieldValue('department', value)}
          value={formik.values.department}
          error={!!formik.errors.department}
          disableNone
        />
        {formik.errors.department && (
          <Grid item>
            <Label color="error" fontWeight={500}>
              {formik.errors.department}
            </Label>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <SelectorElement
          Options={
            uruguayanCities
              .find((city) => city.name === formik.values.department)
              ?.localities.map((locality) => locality) ?? []
          }
          Label="Localidad"
          onValueChange={(value) => formik.setFieldValue('location', value)}
          value={formik.values.location}
          disableNone
          disabled={!formik.values.department}
          error={!!formik.errors.location}
          tooltip={
            !formik.values.department
              ? 'Debe seleccionar un departamento primero'
              : undefined
          }
        />
        {formik.errors.location && (
          <Grid item>
            <Label color="error" fontWeight={500}>
              {formik.errors.location}
            </Label>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <TextField
          label=""
          size={isLargeScreen ? undefined : 'small'}
          sx={{ width: '100%', margin: 0 }}
          value={formik.values.phoneNumber}
          onChange={(e) => formik.setFieldValue('phoneNumber', e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                sx={{ fontSize: isLargeScreen ? undefined : '8' }}>
                Teléfono:
              </InputAdornment>
            )
          }}
          helperText={formik.errors.phoneNumber}
          error={!!formik.errors.phoneNumber}
        />
      </Grid>

      <Grid item xs={12} md={6} mt={{ xs: 0, md: '.5rem' }}>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          disabled={formik.isSubmitting}>
          Crear
        </Button>
      </Grid>
      <Grid item xs={12} md={6} mt={{ xs: 0, md: '.5rem' }}>
        <Button
          type="submit"
          fullWidth
          variant="outlined"
          onClick={handleCancel}>
          Cancelar
        </Button>
      </Grid>

      {errorMessage && (
        <Grid item>
          <Label color="error" fontWeight={500}>
            {errorMessage}
          </Label>
        </Grid>
      )}
    </>
  );
};
export default NewCashier;
