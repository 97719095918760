import {
  Cashier,
  CreateCashierDto,
  RestorePasswordDTO
} from 'redux/casher/types/casher.type';

import { IApiResponse } from 'types/responses/apiResponse.interface';

import { forgotPassword } from './commerce.service';
import { api, baseURL } from './instance';

export const CashierService = {
  create: async (data: CreateCashierDto): Promise<IApiResponse<Cashier>> => {
    const response = await api.post<IApiResponse<Cashier>>(
      `${baseURL}/cashier`,
      data
    );
    return response.data;
  },

  findAll: async (): Promise<IApiResponse<Cashier[]>> => {
    const response = await api.get<IApiResponse<Cashier[]>>(
      `${baseURL}/cashier`
    );
    return response.data;
  },
  findAllDeleted: async (): Promise<IApiResponse<Cashier[]>> => {
    const response = await api.get<IApiResponse<Cashier[]>>(
      `${baseURL}/cashier?status=deleted`
    );
    return response.data;
  },

  findOne: async (id: string): Promise<IApiResponse<Cashier>> => {
    const response = await api.get<IApiResponse<Cashier>>(
      `${baseURL}/cashier/${id}`
    );
    return response.data;
  },

  update: async (
    id: string,
    data: Partial<CreateCashierDto>
  ): Promise<IApiResponse<Cashier>> => {
    const response = await api.patch<IApiResponse<Cashier>>(
      `${baseURL}/cashier/${id}`,
      data
    );
    return response.data;
  },

  remove: async (id: string): Promise<IApiResponse<{}>> => {
    const response = await api.delete<IApiResponse<{}>>(
      `${baseURL}/cashier/${id}`
    );
    return response.data;
  },
  restore: async (id: string): Promise<IApiResponse<{}>> => {
    const response = await api.patch<IApiResponse<{}>>(
      `${baseURL}/cashier/${id}/restore`
    );
    return response.data;
  },
  me: async (): Promise<IApiResponse<Cashier>> => {
    const resp = await api.get<IApiResponse<Cashier>>('/cashier/info');
    return resp.data;
  },
  forgotPassword: async (
    data: forgotPassword
  ): Promise<IApiResponse<string>> => {
    const response = await api.post<IApiResponse<string>>(
      `${baseURL}/cashier/forgot-password`,
      data
    );
    return response.data;
  },

  restorePassword: async (
    data: RestorePasswordDTO
  ): Promise<IApiResponse<string>> => {
    const response = await api.post<IApiResponse<string>>(
      `${baseURL}/cashier/restore-password`,
      data
    );
    return response.data;
  }
};
