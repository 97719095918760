const uruguayanCities = [
  {
    name: 'Artigas',
    localities: [
      'Ciudad de Artigas',
      'Baltasar Brum (Artigas)',
      'Bernabé Rivera (localidad)',
      'Bella Unión',
      'Cainsa',
      'Cerro Ejido',
      'Colonia Palma',
      'Coronado',
      'Cuareím',
      'Cuaró',
      'Diego Lamas',
      'Javier de Viana',
      'Mones Quintela',
      'Paso Campamento',
      'Paso Farías',
      'Pintadito',
      'Rincón de Pacheco',
      'Sequeira',
      'Tomás Gomensoro (Artigas)',
      'Topador'
    ]
  },
  {
    name: 'Canelones',
    localities: [
      'Atlántida',
      'Barros Blancos',
      'Canelones',
      'Ciudad de la Costa',
      'Colonia Nicolich',
      'Empalme Olmos',
      'Joaquín Suárez',
      'La Floresta',
      'Las Piedras',
      'Los Cerrillos',
      'Pando',
      'Parque del Plata',
      'Progreso',
      'San Jacinto',
      'Santa Lucía',
      'Sauce',
      'Shangrilá',
      'Tala',
      'Toledo',
      'Villa Aeroparque',
      'Villa Felicidad',
      'Villa San José'
    ]
  },
  {
    name: 'Cerro Largo',
    localities: [
      'Aceguá',
      'Arachania',
      'Fraile Muerto',
      'Isidoro Noblía',
      'Melo',
      'Paso de los Mellizos',
      'Río Branco',
      'Santa Clara de Olimar',
      'Tupambaé',
      'Vergara',
      'Villa María',
      'Villa del Rosario'
    ]
  },
  {
    name: 'Colonia',
    localities: [
      'Carmelo',
      'Colonia del Sacramento',
      'Conchillas',
      'Florencio Sánchez',
      'Juan L. Lacaze',
      'Nueva Helvecia',
      'Ombúes de Lavalle',
      'Rosario',
      'Tarariras',
      'Valdense',
      'Colonia Valdense',
      'Colonia Miguelete'
    ]
  },
  {
    name: 'Durazno',
    localities: [
      'Blanquillo',
      'Carmen',
      'Centenario',
      'Durazno',
      'La Paloma',
      'Ombúes de Oribe',
      'Sarandí del Yí',
      'Santa Bernardina',
      'Villa del Carmen'
    ]
  },
  {
    name: 'Flores',
    localities: [
      'Andresito',
      'Cerro Colorado',
      'Ismael Cortinas',
      'Juan José Castro',
      'Liberia',
      'Piedras Coloradas',
      'Trinidad'
    ]
  },
  {
    name: 'Florida',
    localities: [
      '25 de Mayo',
      'Cardal',
      'Casupá',
      'Florida',
      'Independencia',
      'La Cruz',
      'Mendoza',
      'Nico Pérez',
      'Sarandí Grande',
      'Veinticinco de Mayo'
    ]
  },
  {
    name: 'Lavalleja',
    localities: [
      'José Pedro Varela',
      'Minas',
      'Solís de Mataojo',
      'Vergara',
      'Zapicán'
    ]
  },
  {
    name: 'Maldonado',
    localities: [
      'Aiguá',
      'Garzón',
      'Maldonado',
      'Piriápolis',
      'Pan de Azúcar',
      'Punta del este',
      'San Carlos',
      'Solís',
      'San Gregorio de Polanco',
      'San Carlos',
      'Sauce de Portezuelo'
    ]
  },
  {
    name: 'Montevideo',
    localities: [
      'AGUADA',
      'AIRES PUROS',
      'ATAHUALPA',
      'BAÑADOS DE CARRASCO',
      'BELVEDERE',
      'BARRIO SUR',
      'BRAZO ORIENTAL',
      'BUCEO',
      'CARRASCO',
      'CARRASCO NORTE',
      'CASABO, PAJAS BLANCAS',
      'CASAVALLE',
      'CASTRO, P. CASTELLANOS',
      'CENTRO',
      'CERRITO',
      'CIUDAD VIEJA',
      'COLON CENTRO Y NOROESTE',
      'COLON SURESTE, ABAYUBA',
      'CONCILIACION',
      'CORDON',
      'FLOR DE MAROÑAS',
      'ITUZAINGO',
      'JACINTO VERA',
      'JARDINES DEL HIPODROMO',
      'LA BLANQUEADA',
      'LA COMERCIAL',
      'LA FIGURITA',
      'LA PALOMA, TOMKINSON',
      'LA TEJA',
      'LARRAÑAGA',
      'LAS ACACIAS',
      'LAS CANTERAS',
      'LEZICA, MELILLA',
      'MALVIN',
      'MALVIN NORTE',
      'MANGA',
      'MANGA, TOLEDO CHICO',
      'MAROÑAS, PARQUE GUARANI',
      'MERCADO MODELO, BOLIVAR',
      'MONTALBAN',
      'NUEVO PARIS',
      'PALERMO',
      'PARQUE BATLLE, V. DOLORES',
      'PARQUE RODO',
      'PASO DE LA ARENA',
      'PASO DE LAS DURANAS',
      'PEÑAROL, LAVALLEJA',
      'PIEDRAS BLANCAS',
      'POCITOS',
      'PQUE. BATLLE, V. DOLORES',
      'PRADO, NUEVA SAVONA',
      'PTA. CARRETAS',
      'PTA. RIELES, BELLA ITALIA',
      'PUEBLO VICTORIA',
      'PUNTA CARRETAS',
      'PUNTA GORDA',
      'REBOLEIRA',
      'REDUCTO',
      'RINCON DEL CERRO',
      'RINCON DEL PINO',
      'RINCON DEL SUCRE',
      'SALINAS, VILLA 25 DE AGOSTO',
      'SAYAGO',
      'SOLYMAR, V. ARENALES',
      'TRES CRUCES',
      'TRES OMBUES, VICTORIA',
      'UNION',
      'VILLA ESPAÑOLA',
      'VILLA GARCIA, MANGA RUR.',
      'VILLA MUNOZ, RETIRO'
    ]
  },
  {
    name: 'Paysandu',
    localities: [
      'Beisso',
      'Casablanca',
      'Chacras de Paysandú',
      'Chapicuy',
      'Colonia Nuevo Paysandú',
      'Constancia',
      'Guichón',
      'La Tentación',
      'Mercedes',
      'Orgoroso',
      'Piedras Coloradas',
      'Porvenir',
      'Pueblo Gallinal',
      'Quebracho',
      'San Félix',
      'Tambores',
      'Termas de Guaviyú',
      'Villa Quebracho',
      'Yapeyú'
    ]
  },
  {
    name: 'Río Negro',
    localities: [
      'Algorta',
      'Bellaco',
      'Carmen',
      'España',
      'Fray Bentos',
      'General Borges',
      'Grecco',
      'Las Cañas',
      'Los Arrayanes',
      'Menafra',
      'Migues',
      'Nuevo Berlín',
      'San Javier',
      'Sarandí de Navarro',
      'Young'
    ]
  },
  {
    name: 'Rivera',
    localities: [
      'Cerro Pelado',
      'Lago Merín',
      'Lapuente',
      'Las Flores',
      'Minas de Corrales',
      'Rivera',
      'Santa Teresa',
      'Tranqueras',
      'Vichadero',
      'La Pedrera'
    ]
  },
  {
    name: 'Rocha',
    localities: [
      'Castillos',
      'Chuy',
      'La Coronilla',
      'La Paloma',
      'La Pedrera',
      'Lascano',
      'Punta del Diablo',
      'Rocha',
      'San Luis al Medio',
      'Santa Teresa',
      'Valizas'
    ]
  },
  {
    name: 'Salto',
    localities: [
      'Biassini',
      'Campo de Todos',
      'Cayetano',
      'Chapicuy',
      'Colonia Itapebí',
      'Colonia Lavalleja',
      'Daymán',
      'Federico Capurro',
      'Garibaldi',
      'La Bolsa',
      'Laureles',
      'Paso Cementerio',
      'Rincón de Valentines',
      'Salto',
      'San Antonio',
      'Sarandí de Arapey',
      'Sauce',
      'Termas del Daymán',
      'Toro Negro',
      'Vera',
      'Villa Constitución'
    ]
  },
  {
    name: 'San José',
    localities: [
      'Ciudad del Plata',
      'Ecilda Paullier',
      'Juan Soler',
      'Kiyú-Ordeig',
      'Libertad',
      'Mal Abrigo',
      'Puntas de Valdez',
      'Puntas de Valdez (estación)',
      'Rafael Perazza',
      'Rodríguez',
      'San José de Mayo',
      'Villa María'
    ]
  },
  {
    name: 'Soriano',
    localities: [
      'Cardona',
      'Chacras de Dolores',
      'Dolores',
      'Egaña',
      'José Enrique Rodó',
      'La Paloma',
      'Mercedes',
      'Palmitas',
      'Risso',
      'Santa Catalina',
      'Villa Soriano'
    ]
  },
  {
    name: 'Tacuarembo',
    localities: [
      'Ansina',
      'Achar',
      'Balneario Iporá',
      'Curtina',
      'Caraguata',
      'Caraguatá',
      'Curtina',
      'La Hilera',
      'Las Toscas',
      'Paso de los Toros',
      'Piedra Sola',
      'San Gregorio de Polanco',
      'Tacuarembó',
      'Tambores',
      'Tres Fuentes',
      'Villa del Carmen',
      'Villa Ansina'
    ]
  },
  {
    name: 'Treinta y Tres',
    localities: [
      'Cerro Chato',
      'Laguna de los Cuervos',
      'Paso de las Tropas',
      'Paso de los Toros',
      'Rincón de la Bolsa',
      'Santa Clara de Olimar',
      'Tacuarembó',
      'Tomas Gomensoro',
      'Treinta y Tres',
      'Vergara',
      'Villa Sara'
    ]
  }
];
export default uruguayanCities;
