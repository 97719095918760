import { useAppDispatch } from 'hooks/useAppDispatch';
import jwtDecode from 'jwt-decode';
import { Navigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import { setUserRole } from 'redux/user/user.slice';

import Roles from 'types/roles.enum';
import { IAuthType, IJwtPayload } from 'types/userType.type';

import { getLocalStorage } from 'utils/localstorage';

interface IProtectedRouteProps {
  children: JSX.Element;
  roles: Roles[];
}

const ProtectedRoute = ({ children, roles }: IProtectedRouteProps) => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const isCorrectRole = () => {
    const token = getLocalStorage('token');

    if (!token) {
      return false;
    }
    try {
      const payload = jwtDecode<IJwtPayload>(token);

      if (roles.includes(Roles.UNKNOW)) {
        return '/commerce/login';
      } else if (
        roles.includes(Roles.ADMIN_COMMERCE) ||
        roles.includes(Roles.COMMERCE)
      ) {
        if ([IAuthType.ADMIN, IAuthType.CASHIER].includes(payload.authType)) {
          if (payload.authType === IAuthType.ADMIN) {
            dispatch(setUserRole(Roles.ADMIN_COMMERCE));
          } else if (payload.authType === IAuthType.CASHIER) {
            dispatch(setUserRole(Roles.COMMERCE));
          }
          return false;
        } else {
          return '/commerce/login';
        }
      } else if (roles.includes(Roles.BUYER)) {
        if (payload.authType === IAuthType.BUYER) {
          dispatch(setUserRole(Roles.BUYER));
          return false;
        } else {
          return '/login';
        }
      }
    } catch {}

    return '/commerce/login';
  };

  const isAuthenticated = () => {
    const token = getLocalStorage('token');

    if (!token) {
      return false;
    }

    return true;
  };

  if (!isAuthenticated()) {
    return (
      <Navigate
        to={{
          pathname: `${
            location.pathname.startsWith('/commerce') ? '/commerce' : ''
          }/login`
        }}
      />
    );
  }

  const redirectToRole = isCorrectRole();

  if (redirectToRole) {
    return <Navigate to={{ pathname: redirectToRole }} />;
  }

  return <>{children}</>;
};
export default ProtectedRoute;
