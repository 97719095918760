import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { useEffect } from 'react';

import { Avatar, Box, Skeleton, Typography } from '@mui/material';

import { Colors } from 'design-system/theme';

import { me as getBuyerInfo } from 'redux/buyer/buyer.action';
import { buyerSelector } from 'redux/buyer/buyer.selector';

const stringAvatar = (name: string) => {
  return {
    sx: {
      bgcolor: '#d9d9d9'
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
  };
};

const BuyerInfo = () => {
  const dispatch = useAppDispatch();
  const { me } = useAppSelector(buyerSelector);

  useEffect(() => {
    dispatch(getBuyerInfo());
  }, [dispatch]);

  return (
    <Box display="flex" alignItems="center">
      {me?.name ? (
        <Avatar {...stringAvatar(me?.name ?? '')} />
      ) : (
        <Skeleton variant="circular" height="2.5rem" width="2.5rem" />
      )}
      <Box ml={2}>
        {me?.name ? (
          <Typography
            variant="body1"
            fontSize={'1.5rem'}
            lineHeight={'1.5rem'}
            fontWeight={700}
            sx={{ letterSpacing: '0.075rem' }}
            color={Colors.primary.main}>
            {`Hola, ${me?.name}`}
          </Typography>
        ) : (
          <Skeleton height="1.5rem" />
        )}
        {me?.document ? (
          <Typography
            variant="body1"
            fontSize={'1.25rem'}
            lineHeight={'1.5rem'}
            fontWeight={600}
            color={Colors.primary.main}>
            {me?.document}
          </Typography>
        ) : (
          <Skeleton height="1.5rem" />
        )}
      </Box>
    </Box>
  );
};
export default BuyerInfo;
