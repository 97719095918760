import { formatDate } from 'helpers/DateTransformer';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import CancelIcon from '@mui/icons-material/Cancel';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import {
  Box,
  Button,
  Pagination,
  PaginationItem,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
  useTheme
} from '@mui/material';

import TransactionsFilters from 'components/TransactionsFilters';

import { Colors } from 'design-system/theme';

import {
  getTransactions,
  rejectRequestAction
} from 'redux/transaction/transaction.action';
import { transactionSelector } from 'redux/transaction/transaction.selector';
import 'redux/transaction/transaction.slice';
import { TransactionStatus } from 'redux/transaction/types/transaction.type';
import { userSelector } from 'redux/user/user.selector';

import Roles from 'types/roles.enum';

import { shortTransactionNumber } from 'utils/shortTransactionNumber';

import CommerceRoutes from '../modules/Commerce/types/routes.enum';

const tableStyles = {
  border: 'none',
  flex: 1,
  color: Colors.gray
};

const StyledTableCell = styled(TableCell)(() => ({
  whiteSpace: 'nowrap',
  width: 'max-content',
  [`&.${tableCellClasses.head}`]: {
    ...tableStyles,
    fontSize: 14,
    fontWeight: 600
  },
  [`&.${tableCellClasses.body}`]: {
    ...tableStyles,
    fontSize: 14,
    fontWeight: 600
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  margin: 0,
  padding: '0',
  borderRadius: '0.5rem',
  border: `1px solid ${theme.palette.primary.main}`,
  justifyContent: 'space-around'
}));

const StyledTableRowBody = styled(TableRow)({
  display: 'flex',
  width: '100%',
  margin: 0,
  padding: '0'
});

const TransactionTable: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const [clickedTransactionId, setClickedTransactionId] = useState<
    string | null
  >('');
  const { transactions, pagination, loading } =
    useAppSelector(transactionSelector);

  const { role } = useAppSelector(userSelector);

  const isCommerce = [Roles.ADMIN_COMMERCE, Roles.COMMERCE].includes(role);
  const headerOptions = [
    'Fecha',
    ...(isCommerce ? ['Caja'] : ['Comercio']),
    'Moneda',
    'Importe',
    'Factura',
    'Transacción',
    ...(isCommerce ? ['Cedula Cliente'] : []),
    'Estado'
  ];

  if (isCommerce) {
    headerOptions.push('Cierre de lote');
    headerOptions.push('Anular');
  }

  const location = useLocation();

  const handleCancelClick = (transactionId: string) => {
    handleCancel(transactionId);
    setClickedTransactionId(transactionId);
  };

  const handleCancel = async (transactionId: string) => {
    const resp = await dispatch(rejectRequestAction(transactionId));

    if (resp.meta.requestStatus === 'fulfilled') {
      dispatch(getTransactions(queryString.parse(location.search)));
    }

    setClickedTransactionId(null);
  };

  useEffect(() => {
    dispatch(getTransactions(queryString.parse(location.search)));
  }, [dispatch, location.search]);

  useEffect(() => {
    if (transactions.length === 0 && pagination.totalPages) {
      navigate({
        search: `${queryString.stringify({
          ...queryString.parse(location.search),
          page: pagination.totalPages
        })}`
      });
    }
  }, [location.search, navigate, pagination.totalPages, transactions.length]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '65vh',
        backgroundColor: theme.palette.background.paper
      }}>
      <TransactionsFilters />
      <TableContainer sx={{ overflowY: 'auto', flexGrow: 1, minWidth: '100%' }}>
        <Table aria-label="Transaction-tables" sx={{ minWidth: '100%' }}>
          <TableHead>
            <StyledTableRow>
              {headerOptions.map((header) => (
                <StyledTableCell
                  key={header}
                  align="center"
                  sx={{ width: header === 'Factura' ? '18rem' : 'auto' }}>
                  {header}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {transactions && !loading
              ? transactions.map((transaction) => (
                  <StyledTableRowBody key={transaction.id} sx={{}}>
                    <StyledTableCell align="center">
                      {formatDate(transaction.createdAt)}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {/**Should be included in the data CAJA */}
                      {isCommerce
                        ? !!transaction.cashier
                          ? transaction.cashier.name
                          : 'Admin'
                        : transaction.commerce.name}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {transaction.currency === 'UYU'
                        ? 'Pesos'
                        : transaction.currency}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {transaction.amount}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {transaction.transactionNumber
                        ? transaction.transactionNumber
                        : '-'}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {shortTransactionNumber(transaction.id)}
                    </StyledTableCell>
                    {isCommerce && (
                      <StyledTableCell align="center">
                        {transaction.buyer ? transaction.buyer.document : 'N/A'}
                      </StyledTableCell>
                    )}

                    <StyledTableCell align="center">
                      {transaction.status === TransactionStatus.APPROVED
                        ? 'Aprobada'
                        : transaction.status === TransactionStatus.CANCELED
                        ? 'Cancelada'
                        : transaction.status === TransactionStatus.PENDING
                        ? 'Pendiente'
                        : [
                            TransactionStatus['REJECTED-BY-CD'],
                            TransactionStatus['REJECTED-BY-BUYER'],
                            TransactionStatus['REJECTED-BY-COMMERCE']
                          ].includes(transaction.status)
                        ? 'Rechazada'
                        : transaction.status === TransactionStatus.ANNULLED
                        ? 'Anulada'
                        : 'Verificada'}
                    </StyledTableCell>

                    {isCommerce && (
                      <StyledTableCell align="center">
                        {transaction.closedLot
                          ? formatDate(transaction.closedLot)
                          : '---'}
                      </StyledTableCell>
                    )}
                    {isCommerce && (
                      <StyledTableCell align="center">
                        <Button
                          onClick={() => handleCancelClick(transaction.id)}
                          disabled={
                            ![
                              TransactionStatus.PENDING,
                              TransactionStatus.APPROVED
                            ].includes(transaction.status)
                          }>
                          {clickedTransactionId !== transaction.id ? (
                            <CancelOutlinedIcon />
                          ) : (
                            <CancelIcon />
                          )}
                        </Button>
                      </StyledTableCell>
                    )}
                  </StyledTableRowBody>
                ))
              : Array(7)
                  .fill(1)
                  .map((a, i) => (
                    <StyledTableRowBody key={i} sx={{}}>
                      <StyledTableCell align="center">
                        <Skeleton sx={{ fontSize: '0.875rem' }} />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Skeleton sx={{ fontSize: '0.875rem' }} />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Skeleton sx={{ fontSize: '0.875rem' }} />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Skeleton sx={{ fontSize: '0.875rem' }} />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Skeleton sx={{ fontSize: '0.875rem' }} />
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        <Skeleton sx={{ fontSize: '0.875rem' }} />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Skeleton sx={{ fontSize: '0.875rem' }} />
                      </StyledTableCell>

                      {isCommerce && (
                        <StyledTableCell align="center">
                          <Skeleton sx={{ fontSize: '0.875rem' }} />
                        </StyledTableCell>
                      )}
                      {isCommerce && (
                        <StyledTableCell align="center">
                          <Skeleton sx={{ fontSize: '0.875rem' }} />
                        </StyledTableCell>
                      )}
                      {isCommerce && (
                        <StyledTableCell align="center">
                          <Box width="3rem">
                            <Skeleton
                              sx={{ margin: 'auto' }}
                              variant="circular"
                              width={24}
                              height={24}
                            />
                          </Box>
                        </StyledTableCell>
                      )}
                    </StyledTableRowBody>
                  ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          marginTop: 2
        }}>
        <Pagination
          color="primary"
          count={pagination.totalPages ?? 1}
          page={pagination.page}
          renderItem={(item) => (
            <PaginationItem
              component={Link}
              to={{
                search: `${queryString.stringify({
                  ...queryString.parse(location.search),
                  page: item.page
                })}`
              }}
              {...item}
            />
          )}
        />
      </Box>
      {isCommerce && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            marginTop: 2,
            width: '14rem',
            maxWidth: '100%'
          }}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            onClick={() => {
              navigate(`/commerce/${CommerceRoutes.TRANSACTIONS_LOT}`);
            }}
            sx={{ mt: 2, mb: 1 }}>
            Realizar cierre de lote
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default TransactionTable;
