import { ReactComponent as BuyerQr } from 'assets/imgs/buyerQr.svg';
import { useAppDispatch } from 'hooks/useAppDispatch';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React from 'react';

import { Close } from '@mui/icons-material';
import { Box, Button, IconButton } from '@mui/material';

import { setModalOpen } from 'redux/commerce/commerce.slice';

interface ApproveProps {
  labelQR: string;
  handlePrint: () => void;
}

const ModalQRCommerce: React.FC<ApproveProps> = ({ labelQR, handlePrint }) => {
  const dispatch = useAppDispatch();
  const isLargeScreen = useIsLargeScreen();

  return (
    <Box
      sx={{
        width: isLargeScreen ? '25rem' : '19rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '2rem',
        position: 'relative',
        margin: 'auto'
      }}>
      {isLargeScreen && (
        <IconButton
          onClick={() => dispatch(setModalOpen(false))}
          sx={{
            position: 'absolute',
            top: -40,
            right: -40
          }}>
          <Close fontSize="large" />
        </IconButton>
      )}
      <Box
        sx={{
          width: isLargeScreen ? '100%' : '18rem',

          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '2rem'
        }}>
        <BuyerQr
          style={{
            width: isLargeScreen ? '100%' : '50%',
            height: isLargeScreen ? '100%' : '50%',
            maxWidth: 240 + 59 * 2,
            maxHeight: 240 + 59 * 2,
            objectFit: 'cover'
          }}
        />
        <Button
          fullWidth
          variant="contained"
          onClick={handlePrint}
          sx={{ width: isLargeScreen ? '21rem' : '15rem', maxWidth: '100%' }}>
          {labelQR}
        </Button>
      </Box>
    </Box>
  );
};

export default ModalQRCommerce;
