export const printQrImage = (imageUrl: string) => {
  const printWindow = window.open('', '_blank');
  if (printWindow && printWindow.document) {
    printWindow.document.write(
      '<html><head><title>Print QR</title></head><body>'
    );
    printWindow.document.write('<style>');
    printWindow.document.write('@page { size: landscape; margin: 0mm; }');
    printWindow.document.write(
      'body { font-family: Arial, sans-serif; display: flex; justify-content: center; align-items: center; height: 100vh; margin: 0; overflow: hidden; }'
    );
    printWindow.document.write('img { max-width: 90%; max-height: 90%; }'); // Reduced to 90% to ensure it doesn't touch the edges
    printWindow.document.write('</style>');
    const img = new Image();
    img.src = imageUrl;
    img.alt = 'QR Code';
    img.onload = () => {
      printWindow.document.body.appendChild(img);
      printWindow.print();
    };
    printWindow.document.write('</body></html>');
    printWindow.document.close();
  }
};
