import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useIsLargeScreen from 'hooks/useIsLargeScreen';

import { Container } from '@mui/material';

import ModalApprove from 'components/ModalApproved';
import ModalLoader from 'components/ModalLoader';
import ModalReject from 'components/ModalRejected';

import Modal from 'design-system/Modal';

import { commerceSelector } from 'redux/commerce/commerce.selector';
import { transactionSelector } from 'redux/transaction/transaction.selector';
import {
  cleanTransactionModal,
  setTransactionModalIsOpen
} from 'redux/transaction/transaction.slice';
import { TransactionModalStatus } from 'redux/transaction/types/transactionState.interface';

import TransactionsDesk from '../components/TransactionsDesk';
import { TransactionsMobile } from '../components/TransactionsMobile';

const TransactionsPage = () => {
  const dispatch = useAppDispatch();
  const isLargeScreen = useIsLargeScreen();
  const { transactionModal } = useAppSelector(transactionSelector);
  const { qrImage } = useAppSelector(commerceSelector);

  const onModalClose = () => {
    dispatch(setTransactionModalIsOpen(false));
    dispatch(cleanTransactionModal());
  };
  return (
    <>
      <Container
        fixed
        sx={{
          minWidth: isLargeScreen ? '90%' : '80%',
          pb: '2rem',
          mt: isLargeScreen ? '7.5rem' : '6rem'
        }}>
        {isLargeScreen ? <TransactionsDesk /> : <TransactionsMobile />}
      </Container>

      <Modal
        open={transactionModal.isOpen}
        onClose={() => {
          onModalClose();
        }}
        {...(transactionModal.status === TransactionModalStatus.WAITING
          ? {
              modalWidth: '28rem',
              modalHeight: '31rem'
            }
          : {})}>
        {transactionModal.status === TransactionModalStatus.ANULLING && (
          <ModalLoader LoaderLabel="Anulando" qrImageURL={qrImage} />
        )}
        {transactionModal.status === TransactionModalStatus.ANULLED && (
          <ModalApprove
            ApproveLabel="Anulación confirmada"
            handleClose={() => {
              onModalClose();
            }}
          />
        )}
        {transactionModal.status === TransactionModalStatus.NOT_ANULLED && (
          <ModalReject
            rejectLabel="No se pudo completar la anulación"
            extraLabel="Más info al 18918"
            handleClose={() => {
              onModalClose();
            }}
          />
        )}
      </Modal>
    </>
  );
};

export default TransactionsPage;
