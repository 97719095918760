import { Form, Formik } from 'formik';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React, { useEffect } from 'react';
import {
  editCashSchema,
  newCashInitialValues
} from 'schemas/CashRegistrations';

import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Skeleton,
  TextField,
  useTheme
} from '@mui/material';

import Label from 'design-system/Label';
import TitleLabel from 'design-system/Layouts/TitleLabels';
import { SelectorElement } from 'design-system/Selector';

import {
  findAllCashiersAction,
  findOneCashierAction,
  updateCashierAction
} from 'redux/casher/casher.action';
import { CashierSelector } from 'redux/casher/casher.selector';
import {
  setCashierModalIsOpen,
  setCashierModalStatus,
  setSelectedCashier
} from 'redux/casher/casher.slice';
import { CashierModalStatus } from 'redux/casher/types/casherState.interface';

import uruguayanCities from 'utils/uruguayanCities';

export const ModalCashEdition: React.FC = () => {
  const isLargeScreen = useIsLargeScreen();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const {
    cashierModal: { selectedCashier },
    loading,
    errorMessage
  } = useAppSelector(CashierSelector);

  useEffect(() => {
    if (!!selectedCashier?.id)
      dispatch(findOneCashierAction(selectedCashier.id));
  }, [dispatch, selectedCashier?.id]);

  const handleSubmit = async (values: any, formikHelpers: any) => {
    if (selectedCashier && selectedCashier.id) {
      const updateData = {
        id: selectedCashier.id,
        data: {
          email: values.email,
          password: values.password,
          name: values.cashID,
          address: values.storeAddress,
          location: values.location,
          department: values.department,
          phoneNumber: values.phoneNumber
        }
      };

      const response = await dispatch(updateCashierAction(updateData));

      if (response.meta.requestStatus === 'fulfilled') {
        if (values.password) {
          dispatch(setCashierModalStatus(CashierModalStatus.UPDATED_PASSWORD));
        } else {
          dispatch(setCashierModalIsOpen(false));
        }
        dispatch(setSelectedCashier(undefined));
        dispatch(findAllCashiersAction());
      }
    }
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          cashID: selectedCashier?.name ?? newCashInitialValues.cashID,
          password: '',
          storeAddress:
            selectedCashier?.address ?? newCashInitialValues.storeAddress,
          location: selectedCashier?.location ?? newCashInitialValues.location,
          department:
            selectedCashier?.department ?? newCashInitialValues.department,
          email: selectedCashier?.email ?? newCashInitialValues.email,
          phoneNumber:
            selectedCashier?.phoneNumber ?? newCashInitialValues.phoneNumber
        }}
        onSubmit={(values, formikHelpers) =>
          handleSubmit(values, formikHelpers)
        }
        validationSchema={editCashSchema}>
        {(formik) => {
          return (
            <Form style={{ width: '100%' }}>
              <Box
                sx={{
                  width: isLargeScreen ? '95%' : '80%',
                  maxWidth: isLargeScreen ? '100%' : undefined,
                  maxHeight: isLargeScreen ? '30rem' : undefined,
                  marginTop: '0',
                  margin: 'auto',
                  marginBottom: '1.5rem',
                  paddingTop: '0',
                  padding: isLargeScreen ? '1rem' : 0,
                  paddingBottom: '1rem',
                  background: theme.palette.background.default
                }}>
                <Box
                  sx={{
                    paddingTop: isLargeScreen ? '0' : '2rem',
                    paddingBottom: '1rem',
                    paddingLeft: '0.5rem'
                  }}>
                  <TitleLabel>Editar caja</TitleLabel>
                </Box>

                <Grid container sx={{ width: '100%' }}>
                  <Grid item sx={{ m: 1 }} xs={12} md={4.6} lg={4.5}>
                    {!loading ? (
                      <TextField
                        label=""
                        size={isLargeScreen ? undefined : 'small'}
                        sx={{ width: '100%', margin: 0 }}
                        value={formik.values.cashID}
                        onChange={(e) =>
                          formik.setFieldValue('cashID', e.target.value)
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              Caja:
                            </InputAdornment>
                          )
                        }}
                        helperText={formik.errors.cashID}
                        error={!!formik.errors.cashID}
                      />
                    ) : (
                      <Skeleton height={isLargeScreen ? '3.5rem' : '2.5rem'} />
                    )}
                  </Grid>
                  <Grid item sx={{ m: 1 }} xs={12} md={6.8} lg={6.95}>
                    {!loading ? (
                      <TextField
                        label=""
                        size={isLargeScreen ? undefined : 'small'}
                        sx={{ width: '100%', margin: 0 }}
                        value={formik.values.email}
                        onChange={(e) =>
                          formik.setFieldValue('email', e.target.value)
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              Correo Electrónico:
                            </InputAdornment>
                          )
                        }}
                        error={!!formik.errors.email}
                      />
                    ) : (
                      <Skeleton height={isLargeScreen ? '3.5rem' : '2.5rem'} />
                    )}
                  </Grid>
                  <Grid item sx={{ m: 1 }} xs={12} md={11.7}>
                    {!loading ? (
                      <TextField
                        label=""
                        size={isLargeScreen ? undefined : 'small'}
                        sx={{ width: '100%', margin: 0 }}
                        value={formik.values.password}
                        helperText={formik.errors.password}
                        onChange={(e) =>
                          formik.setFieldValue('password', e.target.value)
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              Contraseña:
                            </InputAdornment>
                          )
                        }}
                        error={!!formik.errors.password}
                      />
                    ) : (
                      <Skeleton height={isLargeScreen ? '3.5rem' : '2.5rem'} />
                    )}
                  </Grid>
                  <Grid
                    item
                    sx={{ m: 1 }}
                    xs={isLargeScreen ? 5.5 : 12}
                    md={11.7}>
                    {!loading ? (
                      <TextField
                        label=""
                        size={isLargeScreen ? undefined : 'small'}
                        sx={{ width: '100%', margin: 0 }}
                        value={formik.values.storeAddress}
                        helperText={formik.errors.storeAddress}
                        onChange={(e) =>
                          formik.setFieldValue('storeAddress', e.target.value)
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              Dirección de sucursal:
                            </InputAdornment>
                          )
                        }}
                        error={!!formik.errors.storeAddress}
                      />
                    ) : (
                      <Skeleton height={isLargeScreen ? '3.5rem' : '2.5rem'} />
                    )}
                  </Grid>
                  <Grid
                    item
                    sx={{ m: 1 }}
                    xs={isLargeScreen ? 5.5 : 12}
                    sm={5.65}
                    md={3.75}
                    lg={3.7}>
                    {!loading ? (
                      <>
                        <SelectorElement
                          Options={uruguayanCities.map((city) => city.name)}
                          Label="Departamento"
                          onValueChange={(value) =>
                            formik.setFieldValue('department', value)
                          }
                          value={formik.values.department}
                          error={!!formik.errors.department}
                          disableNone
                        />
                        {formik.errors.department && (
                          <Grid item>
                            <Label color="error" fontWeight={500}>
                              {formik.errors.department}
                            </Label>
                          </Grid>
                        )}
                      </>
                    ) : (
                      <Skeleton height={isLargeScreen ? '3.5rem' : '2.5rem'} />
                    )}
                  </Grid>
                  <Grid
                    item
                    sx={{ m: 1 }}
                    xs={isLargeScreen ? 5.5 : 12}
                    sm={5.75}
                    md={3.75}
                    lg={3.8}>
                    {!loading ? (
                      <>
                        <SelectorElement
                          Options={
                            uruguayanCities
                              .find(
                                (city) => city.name === formik.values.department
                              )
                              ?.localities.map((locality) => locality) ?? []
                          }
                          Label="Localidad"
                          onValueChange={(value) =>
                            formik.setFieldValue('location', value)
                          }
                          value={formik.values.location}
                          disableNone
                          disabled={!formik.values.department}
                          error={!!formik.errors.location}
                          tooltip={
                            !formik.values.department
                              ? 'Debe seleccionar un departamento primero'
                              : undefined
                          }
                        />
                        {formik.errors.location && (
                          <Grid item>
                            <Label color="error" fontWeight={500}>
                              {formik.errors.location}
                            </Label>
                          </Grid>
                        )}
                      </>
                    ) : (
                      <Skeleton height={isLargeScreen ? '3.5rem' : '2.5rem'} />
                    )}
                  </Grid>
                  <Grid
                    item
                    sx={{ m: 1 }}
                    xs={isLargeScreen ? 5.5 : 12}
                    sm={5.65}
                    md={3.65}
                    lg={3.8}>
                    {!loading ? (
                      <TextField
                        label=""
                        size={isLargeScreen ? undefined : 'small'}
                        sx={{ width: '100%', margin: 0 }}
                        value={formik.values.phoneNumber}
                        onChange={(e) =>
                          formik.setFieldValue('phoneNumber', e.target.value)
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              Teléfono:
                            </InputAdornment>
                          )
                        }}
                        error={!!formik.errors.phoneNumber}
                        helperText={formik.errors.phoneNumber}
                      />
                    ) : (
                      <Skeleton height={isLargeScreen ? '3.5rem' : '2.5rem'} />
                    )}
                  </Grid>
                  {!loading && (
                    <Grid
                      container
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      spacing={isLargeScreen ? 2 : 0}
                      justifyContent="center">
                      <Grid item xs={isLargeScreen ? 5.75 : 12} sm={5.75}>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          sx={{
                            width: isLargeScreen ? '100%' : '95%',
                            m: 1,
                            fontSize: isLargeScreen ? undefined : 12
                          }}
                          disabled={formik.isSubmitting}>
                          Guardar cambios
                        </Button>
                      </Grid>
                      <Grid item xs={isLargeScreen ? 5.75 : 12} sm={6}>
                        <Button
                          type="submit"
                          fullWidth
                          variant="outlined"
                          onClick={() => dispatch(setCashierModalIsOpen(false))}
                          sx={{
                            width: isLargeScreen ? '100%' : '95%',
                            m: 1,
                            fontSize: isLargeScreen ? undefined : 12
                          }}>
                          Cancelar
                        </Button>
                      </Grid>
                      {errorMessage && (
                        <Grid item>
                          <Label color="error" fontWeight={500}>
                            {errorMessage}
                          </Label>
                        </Grid>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default ModalCashEdition;
