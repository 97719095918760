import { createAsyncThunk } from '@reduxjs/toolkit';
import { CommerceService, loginRequestType } from 'services/commerce.service';

export const commerceLogin = createAsyncThunk(
  'commerce/login',
  async (data: loginRequestType) => {
    return CommerceService.login(data);
  }
);

export const me = createAsyncThunk('commerce/me', async () => {
  return CommerceService.me();
});

export const qrRequestAction = createAsyncThunk(
  'qr/get',
  async (id: string) => {
    return CommerceService.getCommerceQR(id);
  }
);
