import { createAsyncThunk } from '@reduxjs/toolkit';
import { TransactionService } from 'services/transaction.service';

import {
  GetTransactionsQueryParams,
  TransactionRequest
} from 'types/request/transactionCommerce.interface';

export const getTransactions = createAsyncThunk(
  'transaction/get',
  async (params?: GetTransactionsQueryParams) => {
    return TransactionService.get(params);
  }
);

export const getForCloseTransactions = createAsyncThunk(
  'transaction/for-close',
  async (params: { forAll: boolean }) => {
    return TransactionService.forClose(params);
  }
);

export const getPendingTransactions = createAsyncThunk(
  'transaction/get-pendings',
  async () => {
    return TransactionService.get({ forPending: true });
  }
);

export const transactionRequestAction = createAsyncThunk(
  'transaction/create',
  async (data: TransactionRequest) => {
    return TransactionService.transactionRequest(data);
  }
);

export const approveRequestAction = createAsyncThunk(
  'transaction/approve',
  async (id: string) => {
    return TransactionService.approve(id);
  }
);

export const rejectRequestAction = createAsyncThunk(
  'transaction/reject',
  async (id: string) => {
    return TransactionService.reject(id);
  }
);

export const getTransactionById = createAsyncThunk(
  'transaction/get-by-id',
  async (id: string) => {
    return TransactionService.getById(id);
  }
);

export const closeTransactions = createAsyncThunk(
  'transaction/close-transactions',
  async (ids: string[]) => {
    return TransactionService.closeTransactions(ids);
  }
);

export const validateCiAction = createAsyncThunk(
  'transaction/validate-ci',
  async (ci: string) => {
    return TransactionService.validateCi(ci);
  }
);
