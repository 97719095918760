import { Info } from '@mui/icons-material';
import { Box, Link, Typography } from '@mui/material';

import Modal from 'design-system/Modal';

interface ISupportModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

const SupportModal = ({ isOpen, handleClose }: ISupportModalProps) => {
  return (
    <Modal
      open={isOpen}
      onClose={() => handleClose()}
      modalWidth="auto"
      modalHeight="auto">
      <Box p="2rem">
        <Box display="flex" justifyContent="center" mb="2rem">
          <Info color="info" sx={{ fontSize: '6rem' }} />
        </Box>
        <Typography textAlign="center" fontWeight={700} mb="1.5rem">
          Atención al cliente
        </Typography>
        <Typography textAlign="center" mb="1.5rem">
          <Link href="tel:18918" sx={{ textDecoration: 'none' }}>
            Tel: 18918 int. 2
          </Link>
        </Typography>
        <Typography textAlign="center" mb="1.5rem">
          <Link
            href="mailto:info@creditosdirectos.com.uy"
            sx={{ textDecoration: 'none' }}>
            E-mail: info@creditosdirectos.com.uy
          </Link>
        </Typography>
        <Typography textAlign="center" mb="1.5rem">
          <Link
            href="https://wa.me/598092181919"
            sx={{ textDecoration: 'none' }}>
            WhatsApp: 092181919
          </Link>
        </Typography>
      </Box>
    </Modal>
  );
};
export default SupportModal;
