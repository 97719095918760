import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import React, { useEffect } from 'react';

import { findAllDeletedCashiersAction } from 'redux/casher/casher.action';
import { CashierSelector } from 'redux/casher/casher.selector';

import { CashCard } from './CashierCard';
import CashierCardLoader from './CashierCardLoarder';

export const RestoreCashier = () => {
  const dispatch = useAppDispatch();
  const { cashiers: cashUsers, loading: isLoading } =
    useAppSelector(CashierSelector);

  useEffect(() => {
    dispatch(findAllDeletedCashiersAction());
  }, [dispatch]);

  return (
    <>
      {isLoading ? (
        <CashierCardLoader />
      ) : (
        cashUsers.map((casher, index) => (
          <CashCard
            key={index}
            cashIndex={index.toString()}
            casherInfo={casher}
            isDeleted
          />
        ))
      )}
    </>
  );
};

export default RestoreCashier;
