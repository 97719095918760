import { printLotCloseTable } from 'helpers/LotClosePrinterHelper';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Container, useTheme } from '@mui/material';

import TitleLabel from 'design-system/Layouts/TitleLabels';

import { closeTransactions } from 'redux/transaction/transaction.action';
import { transactionSelector } from 'redux/transaction/transaction.selector';
import Currency, {
  Transaction
} from 'redux/transaction/types/transaction.type';

import { CloseLotTableMobile } from './CloseLotTableMobile';

export const CloseLoteMobile: React.FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { transactionsForClose } = useAppSelector(transactionSelector);
  const USDTransactions: Transaction[] = [];
  let USDTransactionsTotal: number = 0;
  const UYUTransactions: Transaction[] = [];
  let UYUTransactionsTotal: number = 0;

  if (transactionsForClose.length > 0)
    transactionsForClose.forEach((transaction) => {
      if (transaction.currency === Currency.UYU) {
        UYUTransactions.push(transaction);
        UYUTransactionsTotal = UYUTransactionsTotal + transaction.amount;
      } else if (transaction.currency === Currency.USD) {
        USDTransactions.push(transaction);
        USDTransactionsTotal = USDTransactionsTotal + transaction.amount;
      }
    });

  const handleCloseLot = async () => {
    const resp = await dispatch(
      closeTransactions([
        ...USDTransactions.map((t) => t.id),
        ...UYUTransactions.map((t) => t.id)
      ])
    );
    if (resp.meta.requestStatus === 'fulfilled') navigate(-1);
  };

  const handlePrint = () => {
    const table = document.querySelector('table');
    if (table) {
      printLotCloseTable(table.outerHTML);
    }
  };
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          backgroundColor: theme.palette.background.paper
        }}
        mt={'5rem'}>
        <Box
          sx={{
            margin: '1rem',
            border: `1px solid ${theme.palette.primary.light}`,
            borderRadius: '0.5rem'
          }}>
          <Container fixed sx={{ minWidth: '100%' }}>
            <Box sx={{ margin: '0.5rem' }}>
              <TitleLabel fontSize="1rem">Resumen de cierre de lote</TitleLabel>
            </Box>
            <CloseLotTableMobile />
          </Container>
        </Box>
        {(USDTransactions.length >= 0 || UYUTransactions.length > 0) && (
          <Box
            sx={{
              display: 'flex',
              gap: '1rem',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <Button
              sx={{ minWidth: '12rem', fontSize: '0.75rem' }}
              onClick={handleCloseLot}
              variant="contained">
              Cerrar lote
            </Button>
            <Button
              sx={{ maxWidth: '12rem', fontSize: '0.75rem' }}
              onClick={handlePrint}
              variant="outlined">
              Imprimir resumen
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
};

export default CloseLoteMobile;
