export const validationDigit = (ci: string) => {
  var a = 0;
  var i = 0;
  if (ci.length <= 6) {
    for (i = ci.length; i < 7; i++) {
      ci = '0' + ci;
    }
  }
  for (i = 0; i < 7; i++) {
    a += (parseInt('2987634'[i]) * parseInt(ci[i])) % 10;
  }
  if (a % 10 === 0) {
    return 0;
  } else {
    return 10 - (a % 10);
  }
};

export const validateCi = (ci: string) => {
  ci = cleanCi(ci);
  const dig = ci[ci.length - 1];
  ci = ci.replace(/[0-9]$/, '');
  return Number(dig) === validationDigit(ci);
};

export const randomCi = () => {
  let ci = Math.floor(Math.random() * 10000000).toString();
  ci = ci.substring(0, 7) + validationDigit(ci);
  return ci;
};

const cleanCi = (ci: string) => ci.replace(/\D/g, '');
