import BuyerQrURL from 'assets/imgs/buyerQr.svg';
import { printQrImage } from 'helpers/qrPrinterHelper';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import React from 'react';

import Modal from 'design-system/Modal';

import { commerceSelector } from 'redux/commerce/commerce.selector';
import { setModalOpen } from 'redux/commerce/commerce.slice';

import ModalQRCommerce from './ModalQR_Commerce';

const CommerceQRModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { isModalOpen } = useAppSelector(commerceSelector);

  const handleQRcommerce = () => {
    printQrImage(BuyerQrURL ?? '');
    dispatch(setModalOpen(false));
  };

  return (
    <>
      <Modal
        open={isModalOpen}
        onClose={() => dispatch(setModalOpen(false))}
        modalHeight={`${415 + 52 * 2}px`}
        modalWidth={`${392 + 52 * 2}px`}>
        <ModalQRCommerce labelQR="Imprimir QR" handlePrint={handleQRcommerce} />
      </Modal>
    </>
  );
};

export default CommerceQRModal;
