import React from 'react';

import { Box, Button, CircularProgress } from '@mui/material';

import Label from 'design-system/Label';

interface LoaderProps {
  LoaderLabel: string;
  qrImageURL?: string;
  showCloseButton?: boolean;
  handleClose?: () => void;
}

const ModalLoader: React.FC<LoaderProps> = ({
  LoaderLabel,
  qrImageURL = '',
  handleClose,
  showCloseButton
}) => {
  const isTransaction = qrImageURL ? true : false;
  return (
    <Box
      sx={{
        margin: '2rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        gap: '2rem'
      }}>
      {isTransaction && (
        <img
          src={qrImageURL}
          alt={'QR Code'}
          style={{
            maxWidth: 127 + 59 * 2,
            maxHeight: 124 + 59 * 2,
            objectFit: 'cover'
          }}
        />
      )}
      <CircularProgress color="primary" size={80} />
      <Label fontSize={20} fontWeight={600}>
        {LoaderLabel}
      </Label>
      {handleClose && showCloseButton && (
        <Button
          variant="contained"
          color="primary"
          onClick={handleClose}
          fullWidth
          style={{ marginTop: '1rem' }}>
          Salir
        </Button>
      )}
    </Box>
  );
};

export default ModalLoader;
