import useIsLargeScreen from 'hooks/useIsLargeScreen';

import { Box, Container } from '@mui/material';

import TransactionsDesk from 'modules/Commerce/components/TransactionsDesk';
import { TransactionsMobile } from 'modules/Commerce/components/TransactionsMobile';

import BuyerInfo from '../components/BuyerInfo';
import PendingTransactions from '../components/PendingTransactions';

const BuyerPage = () => {
  const isLargeScreen = useIsLargeScreen();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      }}
      mt={isLargeScreen ? '7.5rem' : '6rem'}>
      <Container
        fixed
        sx={{ minWidth: isLargeScreen ? '90%' : '80%', pb: '2rem' }}>
        <BuyerInfo />
        <PendingTransactions />
        {isLargeScreen ? <TransactionsDesk /> : <TransactionsMobile />}
      </Container>
    </Box>
  );
};
export default BuyerPage;
