import React from 'react';

import { Box } from '@mui/material';

import TransactionTable from 'components/TransactionsTable';

import TitleLabel from 'design-system/Layouts/TitleLabels';

export const TransactionsDesk = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      }}
      mt={'1.875rem'}>
      <Box sx={{ marginBottom: '0.5rem' }}>
        <TitleLabel>Historial de transacciones</TitleLabel>
      </Box>
      <TransactionTable />
    </Box>
  );
};
export default TransactionsDesk;
