import { createAsyncThunk } from '@reduxjs/toolkit';
import { CashierService } from 'services/cashier.service';
import { forgotPassword } from 'services/commerce.service';

import { CreateCashierDto, RestorePasswordDTO } from './types/casher.type';

export const createCashierAction = createAsyncThunk(
  'cashiers/create',
  async (data: CreateCashierDto, { rejectWithValue }) => {
    try {
      const response = await CashierService.create(data);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const findAllCashiersAction = createAsyncThunk(
  'cashiers/findAll',
  async () => {
    const response = await CashierService.findAll();
    return response.data;
  }
);

export const findAllDeletedCashiersAction = createAsyncThunk(
  'cashiers/findAllDeleted',
  async () => {
    const response = await CashierService.findAllDeleted();
    return response.data;
  }
);

export const findOneCashierAction = createAsyncThunk(
  'cashiers/findOne',
  async (id: string) => {
    const response = await CashierService.findOne(id);
    return response.data;
  }
);

export const updateCashierAction = createAsyncThunk(
  'cashiers/update',
  async (
    { id, data }: { id: string; data: Partial<CreateCashierDto> },
    { rejectWithValue }
  ) => {
    try {
      const response = await CashierService.update(id, data);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const removeCashierAction = createAsyncThunk(
  'cashiers/remove',
  async (id: string) => {
    await CashierService.remove(id);
    return id;
  }
);
export const restoreCashierAction = createAsyncThunk(
  'cashiers/remove',
  async (id: string) => {
    await CashierService.restore(id);
    return id;
  }
);
export const forgotPasswordAction = createAsyncThunk(
  'cashiers/forgotPassword',
  async (data: forgotPassword, { rejectWithValue }) => {
    try {
      const response = await CashierService.forgotPassword(data);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const restorePasswordAction = createAsyncThunk(
  'cashiers/restorePassword',
  async (data: RestorePasswordDTO) => {
    const response = await CashierService.restorePassword(data);
    return response.data;
  }
);

export const me = createAsyncThunk('cashiers/me', async () => {
  const resp = await CashierService.me();
  return resp.data;
});
