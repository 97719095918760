import { useAppDispatch } from 'hooks/useAppDispatch';
import React, { useEffect, useState } from 'react';
import { CommerceService } from 'services/commerce.service';

import { Box } from '@mui/material';

import TitleLabel from 'design-system/Layouts/TitleLabels';

import { me } from 'redux/commerce/commerce.action';

import { PromotionsCarousel } from './PromotionsCarousel';

export const NewsPromotions: React.FC = () => {
  const dispatch = useAppDispatch();
  const [promotions, setPromotions] = useState<any>([]);

  const fetchPromotions = async () => {
    try {
      const fetchedPromotions = await CommerceService.getPromotions();
      setPromotions(fetchedPromotions);
    } catch (error) {}
  };

  const { data: promotionsdata } = promotions;
  useEffect(() => {
    dispatch(me());
    fetchPromotions();
  }, [dispatch]);

  return (
    <Box sx={{ minWidth: '100%' }}>
      <TitleLabel>Novedades</TitleLabel>
      {promotionsdata && promotionsdata.length > 0 && (
        <PromotionsCarousel promotions={promotionsdata} />
      )}
    </Box>
  );
};
