import { ReactComponent as Dots } from 'assets/imgs/dots.svg';
import LogoImg from 'assets/imgs/logo.png';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { Close as CloseIcon } from '@mui/icons-material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Box,
  Divider,
  Drawer as DrawerMUI,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Typography,
  useTheme
} from '@mui/material';

import SupportModal from 'components/SupportModal';

import BuyerSideBarOptions from 'modules/Buyer/components/SidebarOptions';

import CommerceSideBarOptions from 'modules/Commerce/components/SidebarOptions';

import { setModalOpen } from 'redux/commerce/commerce.slice';
import { restoreReduxState } from 'redux/store';

import Roles from 'types/roles.enum';

import { removeLocalStorage } from 'utils/localstorage';

import BackgroundDots from './BackgroundDots';

interface IDrawerProps {
  roles: Roles[];
}
const Drawer = ({ roles }: IDrawerProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [supportModalIsOpen, setSupportModalIsOpen] = useState<boolean>(false);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const toggleDrawer = (value: boolean) => setIsOpen(value);

  const onLogout = () => {
    dispatch(restoreReduxState());
    removeLocalStorage('token');
    navigate(
      `${location.pathname.startsWith('/commerce') ? '/commerce' : ''}/login`
    );
  };

  const handleSupport = () => {};
  return (
    <div>
      <IconButton
        onClick={() => toggleDrawer(true)}
        sx={{
          position: 'fixed',
          left: '2.5rem',
          top: '2.25rem',
          backgroundColor: theme.palette.background.paper,
          borderRadius: '50%',
          zIndex: 10
        }}>
        <MenuIcon />
      </IconButton>
      <Box
        sx={{
          maxWidth: '9.25rem',
          margin: 'auto',
          marginBottom: '4.25rem',
          position: 'fixed',
          right: '2.875rem',
          top: '2rem',
          zIndex: 10,
          backgroundColor: theme.palette.background.paper,
          borderRadius: '1.5rem'
        }}>
        <img src={LogoImg} alt="logo" width={'100%'} />
      </Box>

      <DrawerMUI
        anchor="left"
        open={isOpen}
        onClose={() => toggleDrawer(false)}
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.primary.main,
            width: 400,
            maxWidth: '80%',
            boxSizing: 'content-box'
          },
          elevation: 3
        }}>
        <IconButton
          onClick={() => toggleDrawer(false)}
          sx={{
            color: theme.palette.getContrastText(theme.palette.primary.main),
            position: 'fixed',
            left: '2.5rem',
            top: '2.25rem'
          }}>
          <CloseIcon />
        </IconButton>

        <Box sx={{ marginTop: '8.5rem' }}>
          {roles.includes(Roles.BUYER) && (
            <BuyerSideBarOptions onClick={() => toggleDrawer(false)} />
          )}
          {roles.includes(Roles.COMMERCE) && (
            <CommerceSideBarOptions
              onClick={() => toggleDrawer(false)}
              onOpenModal={() => dispatch(setModalOpen(true))}
            />
          )}
          <Box px="1.25rem">
            <Divider
              sx={{
                borderColor: theme.palette.getContrastText(
                  theme.palette.primary.main
                )
              }}
            />
          </Box>
          <List>
            <ListItem
              disablePadding
              sx={{ display: 'block' }}
              onClick={() => onLogout()}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  px: 2.5
                }}>
                <Typography
                  component="div"
                  variant="body1"
                  px="1.25rem"
                  sx={{
                    color: theme.palette.getContrastText(
                      theme.palette.primary.main
                    ),
                    fontWeight: 700
                  }}>
                  Cerrar sesión
                </Typography>
              </ListItemButton>
            </ListItem>
            <ListItem
              disablePadding
              sx={{ display: 'block' }}
              onClick={() => handleSupport()}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  px: 2.5
                }}
                onClick={() => {
                  setIsOpen(false);
                  setSupportModalIsOpen(true);
                }}>
                <Typography
                  component="div"
                  variant="body1"
                  px="1.25rem"
                  sx={{
                    color: theme.palette.getContrastText(
                      theme.palette.primary.main
                    ),
                    fontWeight: 700
                  }}>
                  <Box display="flex" alignItems="center" gap={1}>
                    <HelpOutlineIcon fontSize="small" /> Soporte
                  </Box>
                </Typography>
              </ListItemButton>
            </ListItem>
          </List>
        </Box>

        <Box
          sx={{
            maxWidth: '9.25rem',
            marginBottom: '4.25rem',
            position: 'fixed',
            left: 0,
            bottom: 0,
            zIndex: -1
          }}>
          <Dots />
        </Box>
        <Box
          sx={{
            maxWidth: '9.25rem',
            margin: 'auto',
            marginBottom: '4.25rem',
            position: 'fixed',
            left: '10rem',
            bottom: 40,
            zIndex: -1
          }}>
          <Dots />
        </Box>
      </DrawerMUI>

      <Box>
        <Outlet />

        <BackgroundDots />
      </Box>

      <SupportModal
        isOpen={supportModalIsOpen}
        handleClose={() => setSupportModalIsOpen(false)}
      />
    </div>
  );
};

export default Drawer;
