import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { CommerceEntity } from 'modules/Commerce/types/commerce.type';

import { TransactionRequest } from 'types/request/transactionCommerce.interface';

import { commerceLogin, me, qrRequestAction } from './commerce.action';
import { ICommerceState } from './types/commerceState.interface';

const initialState: ICommerceState = {
  loading: false,
  transactions: [] as TransactionRequest[],
  qrImage: '',
  isModalOpen: false
};

const commerceSlice = createSlice({
  name: 'commerce',
  initialState,
  reducers: {
    setModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isModalOpen = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(commerceLogin.fulfilled, (state) => {
        state.errorMessage = undefined;
        state.loading = false;
      })
      .addCase(commerceLogin.rejected, (state) => {
        state.errorMessage = 'Datos ingresados incorrectamente';
        state.loading = false;
      })
      .addCase(commerceLogin.pending, (state) => {
        state.errorMessage = undefined;
        state.loading = true;
      })

      .addCase(me.fulfilled, (state, action: PayloadAction<CommerceEntity>) => {
        state.errorMessage = undefined;
        state.loading = false;
        state.commerce = action.payload;
      })
      .addCase(me.rejected, (state) => {
        state.errorMessage = 'error getting commerce info';
        state.commerce = undefined;
      })
      .addCase(me.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        qrRequestAction.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.qrImage = action.payload;
        }
      ); //Agregar pending and rejected
  }
});

export default commerceSlice.reducer;
export const { setModalOpen } = commerceSlice.actions;
