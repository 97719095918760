import Currency, {
  TransactionStatus
} from 'redux/transaction/types/transaction.type';

export interface TransactionRequest {
  document: string;
  name: string;
  transactionNumber: string;
  currency: Currency;
  dues: number;
  amount: number;
}

export enum SortOrderEnum {
  ASC = 'asc',
  DESC = 'desc'
}
export enum SortTransferByEnum {
  TRANSACTION_NUMBER = 'transaction-number',
  BUYER_CI = 'document',
  CURRENCY = 'currency',
  AMOUNT = 'amount',
  DUES = 'dues',
  STATUS = 'status'
}

export type GetTransactionsQueryParams = {
  page?: number;
  sortBy?: SortTransferByEnum;
  sortOrder?: SortOrderEnum;
  term?: string;
  status?: TransactionStatus;
  all?: boolean;
};
