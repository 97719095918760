export const getLocalStorage = (
  key: 'token' | 'buyerUserEmail' | 'userNro' | 'commerceUser'
): string | null => {
  return localStorage.getItem(key);
};

export const setLocalStorage = (
  key: 'token' | 'buyerUserEmail' | 'userNro' | 'commerceUser',
  value: any
): void => {
  localStorage.setItem(key, value);
};

export const removeLocalStorage = (key: 'token'): void => {
  localStorage.removeItem(key);
};
