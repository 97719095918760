import { Cashier } from './casher.type';

export interface ICashiersState {
  errorMessage?: string;
  loading: boolean;
  cashiers: Cashier[];
  me?: Cashier;
  cashierModal: CashierModal;
  forgotPasswordErrorMessage?: string;
}
export type CashierModal = {
  isOpen: boolean;
  selectedCashier: Cashier | undefined;
  status?: CashierModalStatus;
};

export enum CashierModalStatus {
  EDITING = 'edit',
  DELETING = 'delete',
  WAITING = 'waiting',
  NULL = '',
  RESTORING = 'restoring',
  RESTORE_WAITING = 'restore_waiting',
  UPDATED_PASSWORD = 'updated_password'
}
