import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { PromotionCard } from 'design-system/PromotionCard';

interface PromotionsProps {
  promotions: string[];
}
export const PromotionsCarousel: React.FC<PromotionsProps> = ({
  promotions
}) => {
  const isLargeScreen = useIsLargeScreen();
  const [centerPercentage, setCenterPercentage] = useState(100);

  useEffect(() => {
    const getRootFontSize = () =>
      parseFloat(getComputedStyle(document.documentElement).fontSize);
    const getCardWidthInPixels = () => 18 * getRootFontSize();

    const updateCenterPercentage = () => {
      let windowWidth = window.innerWidth;
      let spacingInPixels = 440;

      if (window.innerWidth > 1920) {
        windowWidth = 1920;
        spacingInPixels = 440;
      } else if (window.innerWidth <= 1920 && window.innerWidth > 800) {
        windowWidth = 1700;
        spacingInPixels = 300;
      } else if (window.innerWidth <= 799 && window.innerWidth > 600) {
        windowWidth = 1600;
        spacingInPixels = 580;
      } else if (!isLargeScreen) {
        windowWidth = window.innerWidth;
        spacingInPixels = 20;
      }

      const cardWidthInPixels = getCardWidthInPixels();
      const calculatedCenterPercentage =
        ((cardWidthInPixels + spacingInPixels) / windowWidth) * 100;

      setCenterPercentage(calculatedCenterPercentage);
    };

    updateCenterPercentage();
    window.addEventListener('resize', updateCenterPercentage);
    return () => {
      window.removeEventListener('resize', updateCenterPercentage);
    };
  }, [isLargeScreen]);

  return (
    <Carousel
      showThumbs={false}
      showArrows={true}
      showStatus={false}
      showIndicators={false}
      infiniteLoop={true}
      centerMode={true}
      centerSlidePercentage={centerPercentage}
      transitionTime={3000}
      dynamicHeight={false}
      emulateTouch={false}
      autoPlay={true}
      interval={3000}>
      {promotions.map((promotion, index) => (
        <PromotionCard key={index} imageURL={promotion} index={index} />
      ))}
    </Carousel>
  );
};
