import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import { useEffect } from 'react';

import { Box, Typography } from '@mui/material';

import ModalApprove from 'components/ModalApproved';
import ModalLoader from 'components/ModalLoader';
import ModalReject from 'components/ModalRejected';

import Modal from 'design-system/Modal';
import { Colors } from 'design-system/theme';

import { getPendingTransactions } from 'redux/transaction/transaction.action';
import { transactionSelector } from 'redux/transaction/transaction.selector';
import { setTransactionModalIsOpen } from 'redux/transaction/transaction.slice';
import { TransactionModalStatus } from 'redux/transaction/types/transactionState.interface';

import PendingTransaction from './PendingTransaction';
import { TransactionsMobile } from './TransactionsMobile';

const PendingTransactions = () => {
  const isLargeScreen = useIsLargeScreen();
  const dispatch = useAppDispatch();

  const { pendingTransactions, transactionModal } =
    useAppSelector(transactionSelector);

  useEffect(() => {
    dispatch(getPendingTransactions());
  }, [dispatch]);

  const handleClose = () => {
    dispatch(setTransactionModalIsOpen(false));
  };

  return (
    <>
      {isLargeScreen ? (
        <Box mt="1.875rem">
          {pendingTransactions.length > 0 && (
            <Typography
              color={Colors.primary.main}
              variant="h3"
              fontSize="1.25rem"
              fontWeight={700}
              mb={2}>
              Compras pendientes de aprobación
            </Typography>
          )}
          {pendingTransactions.map((pendingTransaction) => (
            <Box key={pendingTransaction.id} my={3}>
              <PendingTransaction pendingTransaction={pendingTransaction} />
            </Box>
          ))}

          <Modal open={transactionModal.isOpen}>
            {transactionModal.status === TransactionModalStatus.WAITING && (
              <ModalLoader LoaderLabel="Esperando Autorización" />
            )}
            {transactionModal.status === TransactionModalStatus.APPROVED && (
              <ModalApprove
                ApproveLabel="Operación Autorizada"
                handleClose={() => {
                  handleClose();
                }}
              />
            )}
            {transactionModal.status ===
              TransactionModalStatus['REJECTED-BY-BUYER'] && (
              <ModalReject
                rejectLabel="Operación rechazada por el cliente"
                handleClose={() => {
                  handleClose();
                }}
              />
            )}
            {transactionModal.status &&
              [TransactionModalStatus['REJECTED-BY-CD']].includes(
                transactionModal.status
              ) && (
                <ModalReject
                  rejectLabel="Operación rechazada por Créditos Directos"
                  extraLabel="Más info al 18918"
                  handleClose={() => {
                    handleClose();
                  }}
                />
              )}
          </Modal>
        </Box>
      ) : (
        <TransactionsMobile />
      )}
    </>
  );
};
export default PendingTransactions;
