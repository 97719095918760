import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React from 'react';

import { Button, Grid } from '@mui/material';

import Label from 'design-system/Label';

import {
  findAllDeletedCashiersAction,
  removeCashierAction,
  restoreCashierAction
} from 'redux/casher/casher.action';
import { CashierSelector } from 'redux/casher/casher.selector';
import {
  setCashierModalIsOpen,
  setCashierModalStatus
} from 'redux/casher/casher.slice';
import { CashierModalStatus } from 'redux/casher/types/casherState.interface';

export const ModalCashierRestore: React.FC = () => {
  const dispatch = useAppDispatch();
  const isLargeScreen = useIsLargeScreen();
  const { cashierModal } = useAppSelector(CashierSelector);
  const { selectedCashier } = cashierModal;

  const handleDelete = async () => {
    if (selectedCashier?.id) {
      dispatch(setCashierModalStatus(CashierModalStatus.RESTORE_WAITING));
      const resultAction = await dispatch(
        restoreCashierAction(selectedCashier.id)
      );

      if (removeCashierAction.fulfilled.match(resultAction)) {
        dispatch(setCashierModalIsOpen(false));
        dispatch(setCashierModalStatus(CashierModalStatus.NULL));
      }
      if (resultAction.meta.requestStatus === 'fulfilled')
        dispatch(findAllDeletedCashiersAction());
    }
  };
  return (
    <Grid
      container
      sx={{
        width: isLargeScreen ? '100%' : '80%',
        minWidth: isLargeScreen ? undefined : '20rem',
        minHeight: isLargeScreen ? undefined : '10rem',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        gap: isLargeScreen ? undefined : 3
      }}
      spacing={isLargeScreen ? 3 : 0}>
      <Grid
        item
        sx={{
          width: isLargeScreen ? '60%' : '70%',
          height: isLargeScreen ? undefined : '0.25rem',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center'
        }}>
        <Label fontSize={16} fontWeight={500}>
          ¿Estás seguro que deseas restaurar esta caja?
        </Label>
      </Grid>
      <Grid
        container
        item={isLargeScreen ? true : undefined}
        spacing={isLargeScreen ? undefined : 2}
        sx={{ width: isLargeScreen ? undefined : '90%' }}
        xs={isLargeScreen ? 12 : undefined}>
        <Grid item xs={6}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              width: isLargeScreen ? '75%' : '80%',
              fontSize: isLargeScreen ? undefined : 14
            }}
            onClick={handleDelete}>
            Restaurar
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            type="submit"
            fullWidth
            variant="outlined"
            onClick={() => {
              dispatch(setCashierModalIsOpen(false));
              dispatch(setCashierModalStatus(CashierModalStatus.NULL));
            }}
            sx={{
              width: isLargeScreen ? '75%' : '80%',
              fontSize: isLargeScreen ? undefined : 12
            }}>
            Cancelar
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
