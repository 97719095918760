import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React, { useEffect } from 'react';

import { Container, Grid, Skeleton, useTheme } from '@mui/material';

import { NewsPromotions } from 'components/NewsPromotions';

import TitleLabel from 'design-system/Layouts/TitleLabels';

import { me } from 'redux/commerce/commerce.action';
import { commerceSelector } from 'redux/commerce/commerce.selector';

import { CommerceData } from '../components/CommerceData';
import { CommerceNavegation } from '../components/CommerceNavegation';

export const HomePage: React.FC = () => {
  const theme = useTheme();
  const isLargeScreen = useIsLargeScreen();

  const dispatch = useAppDispatch();
  const { commerce: userData } = useAppSelector(commerceSelector);
  useEffect(() => {
    dispatch(me());
  }, [dispatch]);

  return (
    <Container
      maxWidth="md"
      sx={{
        pb: '2rem',
        mt: isLargeScreen ? '7.5rem' : '6rem'
      }}>
      <Grid container alignItems="center">
        <Grid
          item
          sx={{
            marginBottom: isLargeScreen ? '2rem' : '1rem',
            padding: 0
          }}>
          {userData?.name ? (
            <TitleLabel
              fontSize={isLargeScreen ? '1.875rem' : '1.5rem'}>{`Hola ${
              userData ? userData?.name : ''
            }!`}</TitleLabel>
          ) : (
            <Skeleton />
          )}
        </Grid>
        {!isLargeScreen && (
          <Grid
            item
            xs={12}
            sx={{
              marginBottom: '1rem'
            }}>
            <TitleLabel fontSize="1rem">Datos del comercio</TitleLabel>
          </Grid>
        )}
        <Grid
          item
          sx={{
            borderTop: `1px solid ${theme.palette.primary.main}`,
            borderBottom: `1px solid ${theme.palette.primary.main}`,
            backgroundColor: theme.palette.background.default
          }}>
          <CommerceData />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            backgroundColor: theme.palette.background.default
          }}>
          <CommerceNavegation />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            marginTop: '1rem',
            marginBottom: '1.5rem'
          }}>
          <NewsPromotions />
        </Grid>
      </Grid>
    </Container>
  );
};

export default HomePage;
