import useIsLargeScreen from 'hooks/useIsLargeScreen';

import { Typography, TypographyProps } from '@mui/material';

import { Colors } from 'design-system/theme';

interface ILabelProps {
  children: string;
  color?: TypographyProps['color'];
  fontSize?: string;
}

const DescriptionLabel = ({
  children,
  color,
  fontSize = '0.875rem'
}: ILabelProps) => {
  const parts = children.split(':');
  const isLargeScreen = useIsLargeScreen();
  const computedFontSize = isLargeScreen
    ? fontSize
      ? fontSize
      : '0.875rem'
    : 12;
  return (
    <Typography
      fontSize={computedFontSize}
      fontStyle={'normal'}
      fontFamily={'Poppins'}
      color={color ? color : Colors.gray}
      sx={{ display: 'inline' }}>
      <span style={{ fontWeight: 600 }}>{parts[0]}</span>

      {parts[1] && <span style={{ fontWeight: 500 }}>:{parts[1]}</span>}
    </Typography>
  );
};

export default DescriptionLabel;
