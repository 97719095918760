import LogoImg from 'assets/imgs/logo.png';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import { Outlet } from 'react-router-dom';

import { Box } from '@mui/material';

import BackgroundDots from './BackgroundDots';

const JustTheLogoLayout = () => {
  const isLargeScreen = useIsLargeScreen();

  return (
    <Box sx={{ minHeight: '100vh' }}>
      <Box
        sx={{
          maxWidth: '9.25rem',
          margin: 'auto',
          marginBottom: '4.25rem',
          position: 'fixed',
          right: isLargeScreen ? '2.875rem' : '50%',
          top: '2rem',
          transform: isLargeScreen ? undefined : 'translate(50%, 50%)'
        }}>
        <img src={LogoImg} alt="logo" width={'100%'} />
      </Box>
      <Outlet />
      <BackgroundDots />
    </Box>
  );
};
export default JustTheLogoLayout;
