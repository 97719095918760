import { useAppSelector } from 'hooks/useAppSelector';

import HistoryIcon from '@mui/icons-material/History';

import SidebarOptions from 'components/SidebarOptions';

import { SidebarOption } from 'design-system/Layouts/types/sidebarOptions.type';

import { userSelector } from 'redux/user/user.selector';

import Roles from 'types/roles.enum';

import CommerceRoutes from '../types/routes.enum';

const prefix = '/commerce';

interface ICommerceSideBarOptionsProps {
  onClick: () => void;
  onOpenModal: () => void;
}

const CommerceSideBarOptions = ({
  onClick,
  onOpenModal
}: ICommerceSideBarOptionsProps) => {
  const { role } = useAppSelector(userSelector);

  const options: SidebarOption[] = [
    {
      label: 'Principal',
      redirectTo: `${prefix}/${CommerceRoutes.HOME}`,
      icon: <HistoryIcon />
    },
    {
      label: 'Nueva venta',
      redirectTo: `${prefix}/${CommerceRoutes.SELL}`,
      icon: <HistoryIcon />
    },
    {
      label: 'Ver QR',
      redirectTo: `${prefix}/${CommerceRoutes.HOME}`,
      icon: <HistoryIcon />,
      onClick: onOpenModal
    },
    {
      label: 'Historial de transacciones',
      redirectTo: `${prefix}/${CommerceRoutes.TRANSACTIONS}`,
      icon: <HistoryIcon />
    },
    {
      label: 'Cambiar contraseña',
      redirectTo: `${prefix}/${CommerceRoutes.RESTORE_PASSWORD}`,
      icon: <HistoryIcon />
    }
    // {
    //   label: 'Vender',
    //   redirectTo: `${prefix}/${CommerceRoutes.SELL}`,
    //   icon: <></>
    // },

    // {
    //   label: 'Anulacion',
    //   redirectTo: `${prefix}/${CommerceRoutes.ANNULMENT}`,
    //   icon: <CloseIcon />
    // }
  ];
  if (role === Roles.ADMIN_COMMERCE) {
    options.push({
      label: 'Alta de usuario caja',
      redirectTo: `${prefix}/${CommerceRoutes.CASH_REGISTER_CREATION}`,
      icon: <HistoryIcon />
    });
  }
  return <SidebarOptions options={options} onClick={onClick} />;
};
export default CommerceSideBarOptions;
