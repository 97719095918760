import queryString from 'query-string';
import { Location } from 'react-router-dom';

import {
  GetTransactionsQueryParams,
  SortOrderEnum,
  SortTransferByEnum
} from 'types/request/transactionCommerce.interface';

export const updateGetTransactionsSortBy = (params: {
  value: string;
  location: Location;
  sortBy: SortTransferByEnum;
}) => {
  const cp = queryString.parse(
    params.location.search
  ) as GetTransactionsQueryParams;
  if (params.value === '' && cp.sortBy) {
    delete cp.sortBy;
    delete cp.sortOrder;
  } else {
    cp.sortOrder =
      params.value.slice(params.value.length - 3) === SortOrderEnum.ASC
        ? SortOrderEnum.ASC
        : SortOrderEnum.DESC;
    cp.sortBy = params.sortBy;
  }

  return cp;
};
