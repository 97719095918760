import dayjs from 'dayjs';

export const formatDate = (dateInput: Date | string): string => {
  if (!dateInput) {
    console.error('Undefined date input:', dateInput);
    return '';
  }

  return dayjs(dateInput).format('DD-MM-YY');
};
